import { isValid } from 'ssn-validator'
import moment from 'moment'
import validator from 'validator'

/**
 * Generates a website URL link based on the provided base URL.
 * @param {string} link - The base URL link.
 * @returns {string} - The generated website URL link.
 */
export function websiteURLLink(link) {
  switch (link) {
    case 'localhost:3000':
      // For local development
      return 'https://' + link
    case 'stg.exitfund.com':
      // For staging environment
      return `https://stg.exitfund.com`
    case 'exitfund.com':
      // For production environment
      return `https://www.exitfund.com`
    default:
      // Default to an empty string for unrecognized links
      return ''
  }
}
// list of all country name, dial-code, code & flag
export const countryList = [
  {
    name: 'Afghanistan',
    dial_code: '+93',
    code: 'AF',
    flag: '🇦🇫',
  },
  {
    name: 'Åland Islands',
    dial_code: '+358',
    code: 'AX',
    flag: '🇦🇽',
  },
  {
    name: 'Albania',
    dial_code: '+355',
    code: 'AL',
    flag: '🇦🇱',
  },
  {
    name: 'Algeria',
    dial_code: '+213',
    code: 'DZ',
    flag: '🇩🇿',
  },
  {
    name: 'American Samoa',
    dial_code: '+1684',
    code: 'AS',
    flag: '🇦🇸',
  },
  {
    name: 'Andorra',
    dial_code: '+376',
    code: 'AD',
    flag: '🇦🇩',
  },
  {
    name: 'Angola',
    dial_code: '+244',
    code: 'AO',
    flag: '🇦🇴',
  },
  {
    name: 'Anguilla',
    dial_code: '+1264',
    code: 'AI',
    flag: '🇦🇮',
  },
  {
    name: 'Antarctica',
    dial_code: '+672',
    code: 'AQ',
    flag: '🇦🇶',
  },
  {
    name: 'Antigua and Barbuda',
    dial_code: '+1268',
    code: 'AG',
    flag: '🇦🇬',
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR',
    flag: '🇦🇷',
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM',
    flag: '🇦🇲',
  },
  {
    name: 'Aruba',
    dial_code: '+297',
    code: 'AW',
    flag: '🇦🇼',
  },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'AU',
    flag: '🇦🇺',
  },
  {
    name: 'Austria',
    dial_code: '+43',
    code: 'AT',
    flag: '🇦🇹',
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ',
    flag: '🇦🇿',
  },
  {
    name: 'Bahamas',
    dial_code: '+1242',
    code: 'BS',
    flag: '🇧🇸',
  },
  {
    name: 'Bahrain',
    dial_code: '+973',
    code: 'BH',
    flag: '🇧🇭',
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'BD',
    flag: '🇧🇩',
  },
  {
    name: 'Barbados',
    dial_code: '+1246',
    code: 'BB',
    flag: '🇧🇧',
  },
  {
    name: 'Belarus',
    dial_code: '+375',
    code: 'BY',
    flag: '🇧🇾',
  },
  {
    name: 'Belgium',
    dial_code: '+32',
    code: 'BE',
    flag: '🇧🇪',
  },
  {
    name: 'Belize',
    dial_code: '+501',
    code: 'BZ',
    flag: '🇧🇿',
  },
  {
    name: 'Benin',
    dial_code: '+229',
    code: 'BJ',
    flag: '🇧🇯',
  },
  {
    name: 'Bermuda',
    dial_code: '+1441',
    code: 'BM',
    flag: '🇧🇲',
  },
  {
    name: 'Bhutan',
    dial_code: '+975',
    code: 'BT',
    flag: '🇧🇹',
  },
  {
    name: 'Bolivia, Plurinational State of bolivia',
    dial_code: '+591',
    code: 'BO',
    flag: '🇧🇴',
  },
  {
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    code: 'BA',
    flag: '🇧🇦',
  },
  {
    name: 'Botswana',
    dial_code: '+267',
    code: 'BW',
    flag: '🇧🇼',
  },
  {
    name: 'Bouvet Island',
    dial_code: '+47',
    code: 'BV',
    flag: '🏳',
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    code: 'BR',
    flag: '🇧🇷',
  },
  {
    name: 'British Indian Ocean Territory',
    dial_code: '+246',
    code: 'IO',
    flag: '🇮🇴',
  },
  {
    name: 'Brunei Darussalam',
    dial_code: '+673',
    code: 'BN',
    flag: '🇧🇳',
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG',
    flag: '🇧🇬',
  },
  {
    name: 'Burkina Faso',
    dial_code: '+226',
    code: 'BF',
    flag: '🇧🇫',
  },
  {
    name: 'Burundi',
    dial_code: '+257',
    code: 'BI',
    flag: '🇧🇮',
  },
  {
    name: 'Cambodia',
    dial_code: '+855',
    code: 'KH',
    flag: '🇰🇭',
  },
  {
    name: 'Cameroon',
    dial_code: '+237',
    code: 'CM',
    flag: '🇨🇲',
  },
  {
    name: 'Canada',
    dial_code: '+1',
    code: 'CA',
    flag: '🇨🇦',
  },
  {
    name: 'Cape Verde',
    dial_code: '+238',
    code: 'CV',
    flag: '🇨🇻',
  },
  {
    name: 'Cayman Islands',
    dial_code: '+ 345',
    code: 'KY',
    flag: '🇰🇾',
  },
  {
    name: 'Central African Republic',
    dial_code: '+236',
    code: 'CF',
    flag: '🇨🇫',
  },
  {
    name: 'Chad',
    dial_code: '+235',
    code: 'TD',
    flag: '🇹🇩',
  },
  {
    name: 'Chile',
    dial_code: '+56',
    code: 'CL',
    flag: '🇨🇱',
  },
  {
    name: 'China',
    dial_code: '+86',
    code: 'CN',
    flag: '🇨🇳',
  },
  {
    name: 'Christmas Island',
    dial_code: '+61',
    code: 'CX',
    flag: '🇨🇽',
  },
  {
    name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    code: 'CC',
    flag: '🇨🇨',
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO',
    flag: '🇨🇴',
  },
  {
    name: 'Comoros',
    dial_code: '+269',
    code: 'KM',
    flag: '🇰🇲',
  },
  {
    name: 'Congo',
    dial_code: '+242',
    code: 'CG',
    flag: '🇨🇬',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    dial_code: '+243',
    code: 'CD',
    flag: '🇨🇩',
  },
  {
    name: 'Cook Islands',
    dial_code: '+682',
    code: 'CK',
    flag: '🇨🇰',
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR',
    flag: '🇨🇷',
  },
  {
    name: "Cote d'Ivoire",
    dial_code: '+225',
    code: 'CI',
    flag: '🇨🇮',
  },
  {
    name: 'Croatia',
    dial_code: '+385',
    code: 'HR',
    flag: '🇭🇷',
  },
  {
    name: 'Cuba',
    dial_code: '+53',
    code: 'CU',
    flag: '🇨🇺',
  },
  {
    name: 'Cyprus',
    dial_code: '+357',
    code: 'CY',
    flag: '🇨🇾',
  },
  {
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ',
    flag: '🇨🇿',
  },
  {
    name: 'Denmark',
    dial_code: '+45',
    code: 'DK',
    flag: '🇩🇰',
  },
  {
    name: 'Djibouti',
    dial_code: '+253',
    code: 'DJ',
    flag: '🇩🇯',
  },
  {
    name: 'Dominica',
    dial_code: '+1767',
    code: 'DM',
    flag: '🇩🇲',
  },
  {
    name: 'Dominican Republic',
    dial_code: '+1849',
    code: 'DO',
    flag: '🇨🇩',
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    code: 'EC',
    flag: '🇪🇨',
  },
  {
    name: 'Egypt',
    dial_code: '+20',
    code: 'EG',
    flag: '🇪🇬',
  },
  {
    name: 'El Salvador',
    dial_code: '+503',
    code: 'SV',
    flag: '🇸🇻',
  },
  {
    name: 'Equatorial Guinea',
    dial_code: '+240',
    code: 'GQ',
    flag: '🇬🇶',
  },
  {
    name: 'Eritrea',
    dial_code: '+291',
    code: 'ER',
    flag: '🇪🇷',
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE',
    flag: '🇪🇪',
  },
  {
    name: 'Ethiopia',
    dial_code: '+251',
    code: 'ET',
    flag: '🇪🇹',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    code: 'FK',
    flag: '🇫🇰',
  },
  {
    name: 'Faroe Islands',
    dial_code: '+298',
    code: 'FO',
    flag: '🇫🇴',
  },
  {
    name: 'Fiji',
    dial_code: '+679',
    code: 'FJ',
    flag: '🇫🇯',
  },
  {
    name: 'Finland',
    dial_code: '+358',
    code: 'FI',
    flag: '🇫🇮',
  },
  {
    name: 'France',
    dial_code: '+33',
    code: 'FR',
    flag: '🇫🇷',
  },
  {
    name: 'French Guiana',
    dial_code: '+594',
    code: 'GF',
    flag: '🇬🇫',
  },
  {
    name: 'French Polynesia',
    dial_code: '+689',
    code: 'PF',
    flag: '🇵🇫',
  },
  {
    name: 'French Southern Territories',
    dial_code: '+262',
    code: 'TF',
    flag: '🇹🇫',
  },
  {
    name: 'Gabon',
    dial_code: '+241',
    code: 'GA',
    flag: '🇬🇦',
  },
  {
    name: 'Gambia',
    dial_code: '+220',
    code: 'GM',
    flag: '🇬🇲',
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE',
    flag: '🇬🇪',
  },
  {
    name: 'Germany',
    dial_code: '+49',
    code: 'DE',
    flag: '🇩🇪',
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH',
    flag: '🇬🇭',
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'GI',
    flag: '🇬🇮',
  },
  {
    name: 'Greece',
    dial_code: '+30',
    code: 'GR',
    flag: '🇬🇷',
  },
  {
    name: 'Greenland',
    dial_code: '+299',
    code: 'GL',
    flag: '🇬🇱',
  },
  {
    name: 'Grenada',
    dial_code: '+1473',
    code: 'GD',
    flag: '🇬🇩',
  },
  {
    name: 'Guadeloupe',
    dial_code: '+590',
    code: 'GP',
    flag: '🇬🇵',
  },
  {
    name: 'Guam',
    dial_code: '+1671',
    code: 'GU',
    flag: '🇬🇺',
  },
  {
    name: 'Guatemala',
    dial_code: '+502',
    code: 'GT',
    flag: '🇬🇹',
  },
  {
    name: 'Guernsey',
    dial_code: '+44',
    code: 'GG',
    flag: '🇬🇬',
  },
  {
    name: 'Guinea',
    dial_code: '+224',
    code: 'GN',
    flag: '🇬🇳',
  },
  {
    name: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'GW',
    flag: '🇬🇼',
  },
  {
    name: 'Guyana',
    dial_code: '+592',
    code: 'GY',
    flag: '🇬🇾',
  },
  {
    name: 'Haiti',
    dial_code: '+509',
    code: 'HT',
    flag: '🇭🇹',
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    dial_code: '+0',
    code: 'HM',
    flag: '🏳',
  },
  {
    name: 'Holy See (Vatican City State)',
    dial_code: '+379',
    code: 'VA',
    flag: '🇻🇦',
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN',
    flag: '🇭🇳',
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK',
    flag: '🇭🇰',
  },
  {
    name: 'Hungary',
    dial_code: '+36',
    code: 'HU',
    flag: '🇭🇺',
  },
  {
    name: 'Iceland',
    dial_code: '+354',
    code: 'IS',
    flag: '🇮🇸',
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'IN',
    flag: '🇮🇳',
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID',
    flag: '🇮🇩',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    dial_code: '+98',
    code: 'IR',
    flag: '🇮🇷',
  },
  {
    name: 'Iraq',
    dial_code: '+964',
    code: 'IQ',
    flag: '🇮🇶',
  },
  {
    name: 'Ireland',
    dial_code: '+353',
    code: 'IE',
    flag: '🇮🇪',
  },
  {
    name: 'Isle of Man',
    dial_code: '+44',
    code: 'IM',
    flag: '🇮🇲',
  },
  {
    name: 'Israel',
    dial_code: '+972',
    code: 'IL',
    flag: '🇮🇱',
  },
  {
    name: 'Italy',
    dial_code: '+39',
    code: 'IT',
    flag: '🇮🇹',
  },
  {
    name: 'Jamaica',
    dial_code: '+1876',
    code: 'JM',
    flag: '🇯🇲',
  },
  {
    name: 'Japan',
    dial_code: '+81',
    code: 'JP',
    flag: '🇯🇵',
  },
  {
    name: 'Jersey',
    dial_code: '+44',
    code: 'JE',
    flag: '🇯🇪',
  },
  {
    name: 'Jordan',
    dial_code: '+962',
    code: 'JO',
    flag: '🇯🇴',
  },
  {
    name: 'Kazakhstan',
    dial_code: '+7',
    code: 'KZ',
    flag: '🇰🇿',
  },
  {
    name: 'Kenya',
    dial_code: '+254',
    code: 'KE',
    flag: '🇰🇪',
  },
  {
    name: 'Kiribati',
    dial_code: '+686',
    code: 'KI',
    flag: '🇰🇮',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: '+850',
    code: 'KP',
    flag: '🇰🇵',
  },
  {
    name: 'Korea, Republic of South Korea',
    dial_code: '+82',
    code: 'KR',
    flag: '🇰🇷',
  },
  {
    name: 'Kosovo',
    dial_code: '+383',
    code: 'XK',
    flag: '🇽🇰',
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    code: 'KW',
    flag: '🇰🇼',
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG',
    flag: '🇰🇬',
  },
  {
    name: 'Laos',
    dial_code: '+856',
    code: 'LA',
    flag: '🇱🇦',
  },
  {
    name: 'Latvia',
    dial_code: '+371',
    code: 'LV',
    flag: '🇱🇻',
  },
  {
    name: 'Lebanon',
    dial_code: '+961',
    code: 'LB',
    flag: '🇱🇧',
  },
  {
    name: 'Lesotho',
    dial_code: '+266',
    code: 'LS',
    flag: '🇱🇸',
  },
  {
    name: 'Liberia',
    dial_code: '+231',
    code: 'LR',
    flag: '🇱🇷',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    code: 'LY',
    flag: '🇱🇾',
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI',
    flag: '🇱🇮',
  },
  {
    name: 'Lithuania',
    dial_code: '+370',
    code: 'LT',
    flag: '🇱🇹',
  },
  {
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'LU',
    flag: '🇱🇺',
  },
  {
    name: 'Macao',
    dial_code: '+853',
    code: 'MO',
    flag: '🇲🇴',
  },
  {
    name: 'Macedonia',
    dial_code: '+389',
    code: 'MK',
    flag: '🇲🇰',
  },
  {
    name: 'Madagascar',
    dial_code: '+261',
    code: 'MG',
    flag: '🇲🇬',
  },
  {
    name: 'Malawi',
    dial_code: '+265',
    code: 'MW',
    flag: '🇲🇼',
  },
  {
    name: 'Malaysia',
    dial_code: '+60',
    code: 'MY',
    flag: '🇲🇾',
  },
  {
    name: 'Maldives',
    dial_code: '+960',
    code: 'MV',
    flag: '🇲🇻',
  },
  {
    name: 'Mali',
    dial_code: '+223',
    code: 'ML',
    flag: '🇲🇱',
  },
  {
    name: 'Malta',
    dial_code: '+356',
    code: 'MT',
    flag: '🇲🇹',
  },
  {
    name: 'Marshall Islands',
    dial_code: '+692',
    code: 'MH',
    flag: '🇲🇭',
  },
  {
    name: 'Martinique',
    dial_code: '+596',
    code: 'MQ',
    flag: '🇲🇶',
  },
  {
    name: 'Mauritania',
    dial_code: '+222',
    code: 'MR',
    flag: '🇲🇷',
  },
  {
    name: 'Mauritius',
    dial_code: '+230',
    code: 'MU',
    flag: '🇲🇺',
  },
  {
    name: 'Mayotte',
    dial_code: '+262',
    code: 'YT',
    flag: '🇾🇹',
  },
  {
    name: 'Mexico',
    dial_code: '+52',
    code: 'MX',
    flag: '🇲🇽',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dial_code: '+691',
    code: 'FM',
    flag: '🇫🇲',
  },
  {
    name: 'Moldova',
    dial_code: '+373',
    code: 'MD',
    flag: '🇲🇩',
  },
  {
    name: 'Monaco',
    dial_code: '+377',
    code: 'MC',
    flag: '🇲🇨',
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    code: 'MN',
    flag: '🇲🇳',
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    code: 'ME',
    flag: '🇲🇪',
  },
  {
    name: 'Montserrat',
    dial_code: '+1664',
    code: 'MS',
    flag: '🇲🇸',
  },
  {
    name: 'Morocco',
    dial_code: '+212',
    code: 'MA',
    flag: '🇲🇦',
  },
  {
    name: 'Mozambique',
    dial_code: '+258',
    code: 'MZ',
    flag: '🇲🇿',
  },
  {
    name: 'Myanmar',
    dial_code: '+95',
    code: 'MM',
    flag: '🇲🇲',
  },
  {
    name: 'Namibia',
    dial_code: '+264',
    code: 'NA',
    flag: '🇳🇦',
  },
  {
    name: 'Nauru',
    dial_code: '+674',
    code: 'NR',
    flag: '🇳🇷',
  },
  {
    name: 'Nepal',
    dial_code: '+977',
    code: 'NP',
    flag: '🇳🇵',
  },
  {
    name: 'Netherlands',
    dial_code: '+31',
    code: 'NL',
    flag: '🇳🇱',
  },
  {
    name: 'Netherlands Antilles',
    dial_code: '+599',
    code: 'AN',
    flag: '🇳🇱',
  },
  {
    name: 'New Caledonia',
    dial_code: '+687',
    code: 'NC',
    flag: '🇳🇨',
  },
  {
    name: 'New Zealand',
    dial_code: '+64',
    code: 'NZ',
    flag: '🇳🇿',
  },
  {
    name: 'Nicaragua',
    dial_code: '+505',
    code: 'NI',
    flag: '🇳🇮',
  },
  {
    name: 'Niger',
    dial_code: '+227',
    code: 'NE',
    flag: '🇳🇪',
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG',
    flag: '🇳🇬',
  },
  {
    name: 'Niue',
    dial_code: '+683',
    code: 'NU',
    flag: '🇳🇺',
  },
  {
    name: 'Norfolk Island',
    dial_code: '+672',
    code: 'NF',
    flag: '🇳🇫',
  },
  {
    name: 'Northern Mariana Islands',
    dial_code: '+1670',
    code: 'MP',
    flag: '🏳',
  },
  {
    name: 'Norway',
    dial_code: '+47',
    code: 'NO',
    flag: '🇳🇴',
  },
  {
    name: 'Oman',
    dial_code: '+968',
    code: 'OM',
    flag: '🇴🇲',
  },
  {
    name: 'Pakistan',
    dial_code: '+92',
    code: 'PK',
    flag: '🇵🇰',
  },
  {
    name: 'Palau',
    dial_code: '+680',
    code: 'PW',
    flag: '🇵🇼',
  },
  {
    name: 'Palestinian Territory, Occupied',
    dial_code: '+970',
    code: 'PS',
    flag: '🇵🇸',
  },
  {
    name: 'Panama',
    dial_code: '+507',
    code: 'PA',
    flag: '🇵🇦',
  },
  {
    name: 'Papua New Guinea',
    dial_code: '+675',
    code: 'PG',
    flag: '🇵🇬',
  },
  {
    name: 'Paraguay',
    dial_code: '+595',
    code: 'PY',
    flag: '🇵🇾',
  },
  {
    name: 'Peru',
    dial_code: '+51',
    code: 'PE',
    flag: '🇵🇪',
  },
  {
    name: 'Philippines',
    dial_code: '+63',
    code: 'PH',
    flag: '🇵🇭',
  },
  {
    name: 'Pitcairn',
    dial_code: '+64',
    code: 'PN',
    flag: '🇵🇳',
  },
  {
    name: 'Poland',
    dial_code: '+48',
    code: 'PL',
    flag: '🇵🇱',
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT',
    flag: '🇵🇹',
  },
  {
    name: 'Puerto Rico',
    dial_code: '+1939',
    code: 'PR',
    flag: '🇵🇷',
  },
  {
    name: 'Qatar',
    dial_code: '+974',
    code: 'QA',
    flag: '🇶🇦',
  },
  {
    name: 'Romania',
    dial_code: '+40',
    code: 'RO',
    flag: '🇷🇴',
  },
  {
    name: 'Russia',
    dial_code: '+7',
    code: 'RU',
    flag: '🇷🇺',
  },
  {
    name: 'Rwanda',
    dial_code: '+250',
    code: 'RW',
    flag: '🇷🇼',
  },
  {
    name: 'Reunion',
    dial_code: '+262',
    code: 'RE',
    flag: '🇫🇷',
  },
  {
    name: 'Saint Barthelemy',
    dial_code: '+590',
    code: 'BL',
    flag: '🇧🇱',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    code: 'SH',
    flag: '🇸🇭',
  },
  {
    name: 'Saint Kitts and Nevis',
    dial_code: '+1869',
    code: 'KN',
    flag: '🇰🇳',
  },
  {
    name: 'Saint Lucia',
    dial_code: '+1758',
    code: 'LC',
    flag: '🇱🇨',
  },
  {
    name: 'Saint Martin',
    dial_code: '+590',
    code: 'MF',
    flag: '🏳',
  },
  {
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    code: 'PM',
    flag: '🇵🇲',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1784',
    code: 'VC',
    flag: '🇻🇨',
  },
  {
    name: 'Samoa',
    dial_code: '+685',
    code: 'WS',
    flag: '🇼🇸',
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    code: 'SM',
    flag: '🇸🇲',
  },
  {
    name: 'Sao Tome and Principe',
    dial_code: '+239',
    code: 'ST',
    flag: '🇸🇹',
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA',
    flag: '🇸🇦',
  },
  {
    name: 'Senegal',
    dial_code: '+221',
    code: 'SN',
    flag: '🇸🇳',
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    code: 'RS',
    flag: '🇷🇸',
  },
  {
    name: 'Seychelles',
    dial_code: '+248',
    code: 'SC',
    flag: '🇸🇨',
  },
  {
    name: 'Sierra Leone',
    dial_code: '+232',
    code: 'SL',
    flag: '🇸🇱',
  },
  {
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG',
    flag: '🇸🇬',
  },
  {
    name: 'Slovakia',
    dial_code: '+421',
    code: 'SK',
    flag: '🇸🇰',
  },
  {
    name: 'Slovenia',
    dial_code: '+386',
    code: 'SI',
    flag: '🇸🇮',
  },
  {
    name: 'Solomon Islands',
    dial_code: '+677',
    code: 'SB',
    flag: '🇸🇧',
  },
  {
    name: 'Somalia',
    dial_code: '+252',
    code: 'SO',
    flag: '🇸🇴',
  },
  {
    name: 'South Africa',
    dial_code: '+27',
    code: 'ZA',
    flag: '🇿🇦',
  },
  {
    name: 'South Sudan',
    dial_code: '+211',
    code: 'SS',
    flag: '🇸🇸',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    code: 'GS',
    flag: '🇬🇸',
  },
  {
    name: 'Spain',
    dial_code: '+34',
    code: 'ES',
    flag: '🇪🇸',
  },
  {
    name: 'Sri Lanka',
    dial_code: '+94',
    code: 'LK',
    flag: '🇱🇰',
  },
  {
    name: 'Sudan',
    dial_code: '+249',
    code: 'SD',
    flag: '🇸🇩',
  },
  {
    name: 'Suriname',
    dial_code: '+597',
    code: 'SR',
    flag: '🇸🇷',
  },
  {
    name: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    code: 'SJ',
    flag: '🇩🇰',
  },
  {
    name: 'Swaziland',
    dial_code: '+268',
    code: 'SZ',
    flag: '🇸🇿',
  },
  {
    name: 'Sweden',
    dial_code: '+46',
    code: 'SE',
    flag: '🇸🇪',
  },
  {
    name: 'Switzerland',
    dial_code: '+41',
    code: 'CH',
    flag: '🇨🇭',
  },
  {
    name: 'Syrian Arab Republic',
    dial_code: '+963',
    code: 'SY',
    flag: '🇸🇾',
  },
  {
    name: 'Taiwan',
    dial_code: '+886',
    code: 'TW',
    flag: '🇹🇼',
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ',
    flag: '🇹🇯',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dial_code: '+255',
    code: 'TZ',
    flag: '🇹🇿',
  },
  {
    name: 'Thailand',
    dial_code: '+66',
    code: 'TH',
    flag: '🇹🇭',
  },
  {
    name: 'Timor-Leste',
    dial_code: '+670',
    code: 'TL',
    flag: '🇹🇱',
  },
  {
    name: 'Togo',
    dial_code: '+228',
    code: 'TG',
    flag: '🇹🇬',
  },
  {
    name: 'Tokelau',
    dial_code: '+690',
    code: 'TK',
    flag: '🇹🇰',
  },
  {
    name: 'Tonga',
    dial_code: '+676',
    code: 'TO',
    flag: '🇹🇴',
  },
  {
    name: 'Trinidad and Tobago',
    dial_code: '+1868',
    code: 'TT',
    flag: '🇹🇹',
  },
  {
    name: 'Tunisia',
    dial_code: '+216',
    code: 'TN',
    flag: '🇹🇳',
  },
  {
    name: 'Turkey',
    dial_code: '+90',
    code: 'TR',
    flag: '🇹🇷',
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM',
    flag: '🇹🇲',
  },
  {
    name: 'Turks and Caicos Islands',
    dial_code: '+1649',
    code: 'TC',
    flag: '🇹🇨',
  },
  {
    name: 'Tuvalu',
    dial_code: '+688',
    code: 'TV',
    flag: '🇹🇻',
  },
  {
    name: 'Uganda',
    dial_code: '+256',
    code: 'UG',
    flag: '🇺🇬',
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    code: 'UA',
    flag: '🇺🇦',
  },
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE',
    flag: '🇦🇪',
  },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
    flag: '🇬🇧',
  },
  {
    name: 'United States',
    dial_code: '+1',
    code: 'US',
    flag: '🇺🇸',
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    code: 'UY',
    flag: '🇺🇾',
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ',
    flag: '🇺🇿',
  },
  {
    name: 'Vanuatu',
    dial_code: '+678',
    code: 'VU',
    flag: '🇻🇺',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dial_code: '+58',
    code: 'VE',
    flag: '🇻🇪',
  },
  {
    name: 'Vietnam',
    dial_code: '+84',
    code: 'VN',
    flag: '🇻🇳',
  },
  {
    name: 'Virgin Islands, British',
    dial_code: '+1284',
    code: 'VG',
    flag: '🇻🇬',
  },
  {
    name: 'Virgin Islands, U.S.',
    dial_code: '+1340',
    code: 'VI',
    flag: '🇻🇮',
  },
  {
    name: 'Wallis and Futuna',
    dial_code: '+681',
    code: 'WF',
    flag: '🇼🇫',
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    code: 'YE',
    flag: '🇾🇪',
  },
  {
    name: 'Zambia',
    dial_code: '+260',
    code: 'ZM',
    flag: '🇿🇲',
  },
  {
    name: 'Zimbabwe',
    dial_code: '+263',
    code: 'ZW',
    flag: '🇿🇼',
  },
]

const numberRegex = /^\d+$/
/**
 * List of State.
 * @type {string[]}
 */
export const STATE = [
  'AL',
  'AS',
  'CA',
  'IN',
  'MT',
  'AE',
  'HI',
  'NE',
  'KS',
  'WA',
  'GA',
  'WI',
  'MA',
  'NY',
  'CO',
  'VA',
  'NJ',
  'PA',
  'TX',
  'WY',
  'OK',
  'CT',
  'MN',
  'TN',
  'OH',
  'KY',
  'FL',
  'DC',
  'IA',
  'WV',
  'VT',
  'NC',
  'AP',
  'NH',
  'OR',
  'AA',
  'AZ',
  'MS',
  'PR',
  'DE',
  'NM',
  'MD',
  'GU',
  'SD',
  'ND',
  'UT',
  'AR',
  'UM',
  'MO',
  'MP',
  'ME',
  'ID',
  'LA',
  'SC',
  'MI',
  'AK',
  'VI',
  'IL',
  'NV',
  'RI',
]

export const FederalTaxClassifications = [
  'Individual, sole proprietor, or single-member LLC',
  'C-corporation',
  'S-corporation',
  'Partnership',
  'Trust/estate',
  'LLC (classified as C-corporation)',
  'LLC (classified as S-corporation)',
  'LLC (classified as partnership)',
  'Other',
]

export const LimitationOfBenefits = [
  'Government',
  'Tax-exempt pension trust or pension fund',
  'Other tax-exempt organization',
  'Publicly traded corporation',
  'Subsidiary of a publicly traded corporation',
  'Company that meets the ownership and base erosion test',
  'Company that meets the derivative benefits test',
  'Company with an item of income that meets active trade or business test',
  'Favorable discretionary determination by the U.S. competent authority received',
  'No LOB article in treaty',
  'Other',
]

export const Chapter3EntityTypes = [
  'Corporation',
  'Partnership',
  'Simple trust',
  'Grantor trust',
  'Compex trust',
  'Estate',
  'Tax-exempt organization',
  'Foreign government - controlled entity',
  'Foreign government - integral part',
  'Central bank of issue',
  'Private foundation',
  'International organization',
]

export const DREChapter4Statuses = [
  'Branch treated as nonparticipating FFI',
  'Participating FFI',
  'Reporting model 1 FFI',
  'Reporting model 2 FFI',
  'U.S Branch',
]

export const Chapter4Statuses = [
  {
    value: 'Non-Participating FFI',
    subValue:
      'Including an FFI related to a Reporting IGA FFI other than a deemed-compliant FFI, participating FFI, or exempt beneficial owner',
  },
  {
    value: 'Participating FFI',
    subValue: '',
  },
  {
    value: 'Reporting Model 1 FFI',
    subValue: '',
  },
  {
    value: 'Reporting Model 2 FFI',
    subValue: '',
  },
  {
    value: 'Registered deemed-compliant FFI',
    subValue:
      'Other than a reporting Model 1 FFI, sponsored FFI, or nonreporting IGA FFI covered in Part XII. See instructions.',
  },
  {
    value: 'Sponsored FFI',
    subValue: '',
  },
  {
    value: 'Certified deemed-compliant nonregistering local bank',
    subValue: '',
  },
  {
    value: 'Certified deemed-compliant FF with only low-value accounts',
    subValue: '',
  },
  {
    value: 'Certified deemed-compliant sponsored, closely held investment vehicle',
    subValue: '',
  },
  {
    value: 'Certified deemed-compliant limited life debt investment entity',
    subValue: '',
  },
  {
    value: 'Certain investment entities that do not maintain financial accounts',
    subValue: '',
  },
  {
    value: 'Restricted distributor',
    subValue: '',
  },
  {
    value: 'Nonreporting IGA FFI',
    subValue: '',
  },
  {
    value: 'Foreign government, government of a U.S. possession, or foreign central bank of issue',
    subValue: '',
  },
  {
    value: 'International organization',
    subValue: '',
  },
  {
    value: 'Exempt retirement plans',
    subValue: '',
  },
  {
    value: 'Entity wholly owned by exempt beneficial owners',
    subValue: '',
  },
  {
    value: 'Territory financial institution',
    subValue: '',
  },
  {
    value: 'Excepted nonfinancial group entity',
    subValue: '',
  },
  {
    value: 'Excepted nonfinancial start-up company',
    subValue: '',
  },
  {
    value: 'Excepted nonfinancial entity in liquidation or bankruptcy',
    subValue: '',
  },
  {
    value: '501(c) organization',
    subValue: '',
  },
  {
    value: 'Nonprofit organization',
    subValue: '',
  },
  {
    value: 'Publicly traded NFFE or NFFE affiliate of a publicly traded corporation',
    subValue: '',
  },
  {
    value: 'Excepted territory NFFE',
    subValue: '',
  },
  {
    value: 'Active NFFE',
    subValue: '',
  },
  {
    value: 'Passive NFFE',
    subValue: '',
  },
  {
    value: 'Excepted inter-affiliate FFI',
    subValue: '',
  },
  {
    value: 'Direct reporting NFFE',
    subValue: '',
  },
  {
    value: 'Sponsored direct reporting NFFE',
    subValue: '',
  },
  {
    value: 'Account that is not a financial account',
    subValue: '',
  },
]

/**
 * List of countries with corresponding labels and keys.
 * @type {Object[]}
 * Each object has a label (the country's name) and a key (the country's code).
 */
export const COUNTRY_LIST = [
  {
    label: 'Afghanistan',
    key: 'AF',
  },
  {
    label: 'Albania',
    key: 'AL',
  },
  {
    label: 'Algeria',
    key: 'DZ',
  },
  {
    label: 'American Samoa',
    key: 'AS',
  },
  {
    label: 'Andorra',
    key: 'AD',
  },
  {
    label: 'Angola',
    key: 'AO',
  },
  {
    label: 'Anguilla',
    key: 'AI',
  },
  {
    label: 'Antarctica',
    key: 'AQ',
  },
  {
    label: 'Antigua and Barbuda',
    key: 'AG',
  },
  {
    label: 'Argentina',
    key: 'AR',
  },
  {
    label: 'Armenia',
    key: 'AM',
  },
  {
    label: 'Aruba',
    key: 'AW',
  },
  {
    label: 'Australia',
    key: 'AU',
  },
  {
    label: 'Austria',
    key: 'AT',
  },
  {
    label: 'Azerbaijan',
    key: 'AZ',
  },
  {
    label: 'Bahamas',
    key: 'BS',
  },
  {
    label: 'Bahrain',
    key: 'BH',
  },
  {
    label: 'Bangladesh',
    key: 'BD',
  },
  {
    label: 'Barbados',
    key: 'BB',
  },
  {
    label: 'Belarus',
    key: 'BY',
  },
  {
    label: 'Belgium',
    key: 'BE',
  },
  {
    label: 'Belize',
    key: 'BZ',
  },
  {
    label: 'Benin',
    key: 'BJ',
  },
  {
    label: 'Bermuda',
    key: 'BM',
  },
  {
    label: 'Bhutan',
    key: 'BT',
  },
  {
    label: 'Bolivia (Plurinational State of)',
    key: 'BO',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    key: 'BQ',
  },
  {
    label: 'Bosnia and Herzegovina',
    key: 'BA',
  },
  {
    label: 'Botswana',
    key: 'BW',
  },
  {
    label: 'Bouvet Island',
    key: 'BV',
  },
  {
    label: 'Brazil',
    key: 'BR',
  },
  {
    label: 'British Indian Ocean Territory',
    key: 'IO',
  },
  {
    label: 'Brunei Darussalam',
    key: 'BN',
  },
  {
    label: 'Bulgaria',
    key: 'BG',
  },
  {
    label: 'Burkina Faso',
    key: 'BF',
  },
  {
    label: 'Burundi',
    key: 'BI',
  },
  {
    label: 'Cabo Verde',
    key: 'CV',
  },
  {
    label: 'Cambodia',
    key: 'KH',
  },
  {
    label: 'Cameroon',
    key: 'CM',
  },
  {
    label: 'Canada',
    key: 'CA',
  },
  {
    label: 'Cayman Islands',
    key: 'KY',
  },
  {
    label: 'Central African Republic',
    key: 'CF',
  },
  {
    label: 'Chad',
    key: 'TD',
  },
  {
    label: 'Chile',
    key: 'CL',
  },
  {
    label: 'China',
    key: 'CN',
  },
  {
    label: 'Christmas Island',
    key: 'CX',
  },
  {
    label: 'Cocos (Keeling) Islands',
    key: 'CC',
  },
  {
    label: 'Colombia',
    key: 'CO',
  },
  {
    label: 'Comoros',
    key: 'KM',
  },
  {
    label: 'Congo (the Democratic Republic of the)',
    key: 'CD',
  },
  {
    label: 'Congo',
    key: 'CG',
  },
  {
    label: 'Cook Islands',
    key: 'CK',
  },
  {
    label: 'Costa Rica',
    key: 'CR',
  },
  {
    label: 'Croatia',
    key: 'HR',
  },
  {
    label: 'Cuba',
    key: 'CU',
  },
  {
    label: 'Curaçao',
    key: 'CW',
  },
  {
    label: 'Cyprus',
    key: 'CY',
  },
  {
    label: 'Czechia',
    key: 'CZ',
  },
  {
    label: "Côte d'Ivoire",
    key: 'CI',
  },
  {
    label: 'Denmark',
    key: 'DK',
  },
  {
    label: 'Djibouti',
    key: 'DJ',
  },
  {
    label: 'Dominica',
    key: 'DM',
  },
  {
    label: 'Dominican Republic',
    key: 'DO',
  },
  {
    label: 'Ecuador',
    key: 'EC',
  },
  {
    label: 'Egypt',
    key: 'EG',
  },
  {
    label: 'El Salvador',
    key: 'SV',
  },
  {
    label: 'Equatorial Guinea',
    key: 'GQ',
  },
  {
    label: 'Eritrea',
    key: 'ER',
  },
  {
    label: 'Estonia',
    key: 'EE',
  },
  {
    label: 'Eswatini',
    key: 'SZ',
  },
  {
    label: 'Ethiopia',
    key: 'ET',
  },
  {
    label: 'Falkland Islands',
    key: 'FK',
  },
  {
    label: 'Faroe Islands',
    key: 'FO',
  },
  {
    label: 'Fiji',
    key: 'FJ',
  },
  {
    label: 'Finland',
    key: 'FI',
  },
  {
    label: 'France',
    key: 'FR',
  },
  {
    label: 'French Guiana',
    key: 'GF',
  },
  {
    label: 'French Polynesia',
    key: 'PF',
  },
  {
    label: 'French Southern Territories',
    key: 'TF',
  },
  {
    label: 'Gabon',
    key: 'GA',
  },
  {
    label: 'Gambia',
    key: 'GM',
  },
  {
    label: 'Georgia',
    key: 'GE',
  },
  {
    label: 'Germany',
    key: 'DE',
  },
  {
    label: 'Ghana',
    key: 'GH',
  },
  {
    label: 'Gibraltar',
    key: 'GI',
  },
  {
    label: 'Greece',
    key: 'GR',
  },
  {
    label: 'Greenland',
    key: 'GL',
  },
  {
    label: 'Grenada',
    key: 'GD',
  },
  {
    label: 'Guadeloupe',
    key: 'GP',
  },
  {
    label: 'Guam',
    key: 'GU',
  },
  {
    label: 'Guatemala',
    key: 'GT',
  },
  {
    label: 'Guernsey',
    key: 'GG',
  },
  {
    label: 'Guinea',
    key: 'GN',
  },
  {
    label: 'Guinea-Bissau',
    key: 'GW',
  },
  {
    label: 'Guyana',
    key: 'GY',
  },
  {
    label: 'Haiti',
    key: 'HT',
  },
  {
    label: 'Heard Island and McDonald Islands',
    key: 'HM',
  },
  {
    label: 'Holy See',
    key: 'VA',
  },
  {
    label: 'Honduras',
    key: 'HN',
  },
  {
    label: 'Hong Kong',
    key: 'HK',
  },
  {
    label: 'Hungary',
    key: 'HU',
  },
  {
    label: 'Iceland',
    key: 'IS',
  },
  {
    label: 'India',
    key: 'IN',
  },
  {
    label: 'Indonesia',
    key: 'ID',
  },
  {
    label: 'Iran (Islamic Republic of)',
    key: 'IR',
  },
  {
    label: 'Iraq',
    key: 'IQ',
  },
  {
    label: 'Ireland',
    key: 'IE',
  },
  {
    label: 'Isle of Man',
    key: 'IM',
  },
  {
    label: 'Israel',
    key: 'IL',
  },
  {
    label: 'Italy',
    key: 'IT',
  },
  {
    label: 'Jamaica',
    key: 'JM',
  },
  {
    label: 'Japan',
    key: 'JP',
  },
  {
    label: 'Jersey',
    key: 'JE',
  },
  {
    label: 'Jordan',
    key: 'JO',
  },
  {
    label: 'Kazakhstan',
    key: 'KZ',
  },
  {
    label: 'Kenya',
    key: 'KE',
  },
  {
    label: 'Kiribati',
    key: 'KI',
  },
  {
    label: "Korea (the Democratic People's Republic of)",
    key: 'KP',
  },
  {
    label: 'Korea (the Republic of)',
    key: 'KR',
  },
  {
    label: 'Kuwait',
    key: 'KW',
  },
  {
    label: 'Kyrgyzstan',
    key: 'KG',
  },
  {
    label: "Lao People's Democratic Republic",
    key: 'LA',
  },
  {
    label: 'Latvia',
    key: 'LV',
  },
  {
    label: 'Lebanon',
    key: 'LB',
  },
  {
    label: 'Lesotho',
    key: 'LS',
  },
  {
    label: 'Liberia',
    key: 'LR',
  },
  {
    label: 'Libya',
    key: 'LY',
  },
  {
    label: 'Liechtenstein',
    key: 'LI',
  },
  {
    label: 'Lithuania',
    key: 'LT',
  },
  {
    label: 'Luxembourg',
    key: 'LU',
  },
  {
    label: 'Macao',
    key: 'MO',
  },
  {
    label: 'Madagascar',
    key: 'MG',
  },
  {
    label: 'Malawi',
    key: 'MW',
  },
  {
    label: 'Malaysia',
    key: 'MY',
  },
  {
    label: 'Maldives',
    key: 'MV',
  },
  {
    label: 'Mali',
    key: 'ML',
  },
  {
    label: 'Malta',
    key: 'MT',
  },
  {
    label: 'Marshall Islands',
    key: 'MH',
  },
  {
    label: 'Martinique',
    key: 'MQ',
  },
  {
    label: 'Mauritania',
    key: 'MR',
  },
  {
    label: 'Mauritius',
    key: 'MU',
  },
  {
    label: 'Mayotte',
    key: 'YT',
  },
  {
    label: 'Mexico',
    key: 'MX',
  },
  {
    label: 'Micronesia (Federated States of)',
    key: 'FM',
  },
  {
    label: 'Moldova (the Republic of)',
    key: 'MD',
  },
  {
    label: 'Monaco',
    key: 'MC',
  },
  {
    label: 'Mongolia',
    key: 'MN',
  },
  {
    label: 'Montenegro',
    key: 'ME',
  },
  {
    label: 'Montserrat',
    key: 'MS',
  },
  {
    label: 'Morocco',
    key: 'MA',
  },
  {
    label: 'Mozambique',
    key: 'MZ',
  },
  {
    label: 'Myanmar',
    key: 'MM',
  },
  {
    label: 'Namibia',
    key: 'NA',
  },
  {
    label: 'Nauru',
    key: 'NR',
  },
  {
    label: 'Nepal',
    key: 'NP',
  },
  {
    label: 'Netherlands',
    key: 'NL',
  },
  {
    label: 'New Caledonia',
    key: 'NC',
  },
  {
    label: 'New Zealand',
    key: 'NZ',
  },
  {
    label: 'Nicaragua',
    key: 'NI',
  },
  {
    label: 'Niger',
    key: 'NE',
  },
  {
    label: 'Nigeria',
    key: 'NG',
  },
  {
    label: 'Niue',
    key: 'NU',
  },
  {
    label: 'Norfolk Island',
    key: 'NF',
  },
  {
    label: 'Northern Mariana Islands',
    key: 'MP',
  },
  {
    label: 'Norway',
    key: 'NO',
  },
  {
    label: 'Oman',
    key: 'OM',
  },
  {
    label: 'Pakistan',
    key: 'PK',
  },
  {
    label: 'Palau',
    key: 'PW',
  },
  {
    label: 'Palestine, State of',
    key: 'PS',
  },
  {
    label: 'Panama',
    key: 'PA',
  },
  {
    label: 'Papua New Guinea',
    key: 'PG',
  },
  {
    label: 'Paraguay',
    key: 'PY',
  },
  {
    label: 'Peru',
    key: 'PE',
  },
  {
    label: 'Philippines',
    key: 'PH',
  },
  {
    label: 'Pitcairn',
    key: 'PN',
  },
  {
    label: 'Poland',
    key: 'PL',
  },
  {
    label: 'Portugal',
    key: 'PT',
  },
  {
    label: 'Puerto Rico',
    key: 'PR',
  },
  {
    label: 'Qatar',
    key: 'QA',
  },
  {
    label: 'Republic of North Macedonia',
    key: 'MK',
  },
  {
    label: 'Romania',
    key: 'RO',
  },
  {
    label: 'Russian Federation',
    key: 'RU',
  },
  {
    label: 'Rwanda',
    key: 'RW',
  },
  {
    label: 'Réunion',
    key: 'RE',
  },
  {
    label: 'Saint Barthélemy',
    key: 'BL',
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    key: 'SH',
  },
  {
    label: 'Saint Kitts and Nevis',
    key: 'KN',
  },
  {
    label: 'Saint Lucia',
    key: 'LC',
  },
  {
    label: 'Saint Martin (French part)',
    key: 'MF',
  },
  {
    label: 'Saint Pierre and Miquelon',
    key: 'PM',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    key: 'VC',
  },
  {
    label: 'Samoa',
    key: 'WS',
  },
  {
    label: 'San Marino',
    key: 'SM',
  },
  {
    label: 'Sao Tome and Principe',
    key: 'ST',
  },
  {
    label: 'Saudi Arabia',
    key: 'SA',
  },
  {
    label: 'Senegal',
    key: 'SN',
  },
  {
    label: 'Serbia',
    key: 'RS',
  },
  {
    label: 'Seychelles',
    key: 'SC',
  },
  {
    label: 'Sierra Leone',
    key: 'SL',
  },
  {
    label: 'Singapore',
    key: 'SG',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    key: 'SX',
  },
  {
    label: 'Slovakia',
    key: 'SK',
  },
  {
    label: 'Slovenia',
    key: 'SI',
  },
  {
    label: 'Solomon Islands',
    key: 'SB',
  },
  {
    label: 'Somalia',
    key: 'SO',
  },
  {
    label: 'South Africa',
    key: 'ZA',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    key: 'GS',
  },
  {
    label: 'South Sudan',
    key: 'SS',
  },
  {
    label: 'Spain',
    key: 'ES',
  },
  {
    label: 'Sri Lanka',
    key: 'LK',
  },
  {
    label: 'Sudan',
    key: 'SD',
  },
  {
    label: 'Suriname',
    key: 'SR',
  },
  {
    label: 'Svalbard and Jan Mayen',
    key: 'SJ',
  },
  {
    label: 'Sweden',
    key: 'SE',
  },
  {
    label: 'Switzerland',
    key: 'CH',
  },
  {
    label: 'Syrian Arab Republic',
    key: 'SY',
  },
  {
    label: 'Taiwan (Province of China)',
    key: 'TW',
  },
  {
    label: 'Tajikistan',
    key: 'TJ',
  },
  {
    label: 'Tanzania, United Republic of',
    key: 'TZ',
  },
  {
    label: 'Thailand',
    key: 'TH',
  },
  {
    label: 'Timor-Leste',
    key: 'TL',
  },
  {
    label: 'Togo',
    key: 'TG',
  },
  {
    label: 'Tokelau',
    key: 'TK',
  },
  {
    label: 'Tonga',
    key: 'TO',
  },
  {
    label: 'Trinidad and Tobago',
    key: 'TT',
  },
  {
    label: 'Tunisia',
    key: 'TN',
  },
  {
    label: 'Turkey',
    key: 'TR',
  },
  {
    label: 'Turkmenistan',
    key: 'TM',
  },
  {
    label: 'Turks and Caicos Islands',
    key: 'TC',
  },
  {
    label: 'Tuvalu',
    key: 'TV',
  },
  {
    label: 'Uganda',
    key: 'UG',
  },
  {
    label: 'Ukraine',
    key: 'UA',
  },
  {
    label: 'United Arab Emirates',
    key: 'AE',
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    key: 'GB',
  },
  {
    label: 'United States Minor Outlying Islands',
    key: 'UM',
  },
  {
    label: 'United States of America',
    key: 'US',
  },
  {
    label: 'Uruguay',
    key: 'UY',
  },
  {
    label: 'Uzbekistan',
    key: 'UZ',
  },
  {
    label: 'Vanuatu',
    key: 'VU',
  },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    key: 'VE',
  },
  {
    label: 'Viet Nam',
    key: 'VN',
  },
  {
    label: 'Virgin Islands (British)',
    key: 'VG',
  },
  {
    label: 'Virgin Islands (U.S.)',
    key: 'VI',
  },
  {
    label: 'Wallis and Futuna',
    key: 'WF',
  },
  {
    label: 'Western Sahara',
    key: 'EH',
  },
  {
    label: 'Yemen',
    key: 'YE',
  },
  {
    label: 'Zambia',
    key: 'ZM',
  },
  {
    label: 'Zimbabwe',
    key: 'ZW',
  },
  {
    label: 'Åland Islands',
    key: 'AX',
  },
]

/**
 * Object defining routes based on the status of a startup.
 * @type {Object}
 */
export const startupRoute = {
  //  Routes for startups with an 'incomplete' status.
  incomplete: ['/startup/thankyou', '/startup/onboarding'],
  //  Routes for startups with an 'pending' status.
  pending: ['/startup/thankyou'],
  // Routes for startups with an 'tracking' status.
  tracking: [
    '/tracking/monthly-update',
    '/tracking/submit-monthly-update',
    '/tracking/edit-monthly-update/:id',
    '/tracking/history',
    '/tracking/profile/company',
    '/tracking/profile/team',
    '/tracking/profile/round',
    '/tracking/profile/documents',
    '/tracking/profile/videos',
    '/tracking/setting/shareLink',
    '/tracking/setting/EmailNotification',
    '/tracking/setting/ChangePassword',
    '/tracking/preview',
    '/tracking/notifications',
    '/startup/thankyou',
  ],
  //  Routes for startups with an 'approved' status.
  approved: [
    '/startup/thankyou',
    '/startup/approve/profile/company',
    '/startup/approve/profile/team',
    '/startup/approve/profile/round',
    '/startup/approve/profile/documents',
    '/startup/approve/profile/videos',
    '/startup/approve/setting/companyInfo',
    '/startup/approve/setting/publicLink',
    '/startup/approve/setting/Banking',
    '/startup/approve/setting/EmailNotifications',
    '/startup/approve/setting/ChangePassword',
    '/startup/approve/dashboard-status',
    '/startup/approve/dashboard-q&a-forum',
    '/startup/approve/investors-history',
    '/startup/approve/investors-feedback',
    '/startup/approve/monthly-update',
    '/startup/approve/monthly-update/submit-update',
    `/startup/approve/edit-monthly-update/:id`,
    '/startup/approve/monthly-update/analytics',
    '/startup/approve/notification',
    '/startup/approve/preview',
    '/startup/approve/otpinput',
    '/startup/approve/investor-detail/:id',
    '/startup/approve/fundraise/invite-investor',
    '/startup/approve/fundraise/invite-history',
    '/startup/approve/fundraise/posts',
    '/startup/approve/fundraise/post-all',
    '/startup/approved/fundraise/view-history/:id',
  ],
  //  Routes for startups with an 'rejected' status.
  rejected: ['/startup/thankyou'],
}

/**
 * Performs validation based on the provided checkObj, data, and error.
 *
 * @param {string[]} checkObj - List of fields to check.
 * @param {Object} data - Data object to validate.
 * @param {Object} error - Object to store validation errors.
 * @returns {Object} Object with validation results.
 */
export const onSubmitValidation = (checkObj, data, error) => {
  let errObj = error
  let IsValid = true
  let errorShow = true
  checkObj.map(item => {
    if (!data?.[item]) {
      errObj = { ...errObj, [item]: 'This field is required' }
      IsValid = false
    } else if (item == 'email') {
      // let emailregx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z-]+(?:\.[a-zA-Z0-9-]+)*$/
      let emailregx = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/
      if (!emailregx.test(data?.[item])) {
        errObj = { ...errObj, [item]: 'Invalid email address' }
        IsValid = false
      }
    } else if (item == 'ssn' || item == 'trustee_ssn') {
      if (data?.[item].length < 9) {
        errObj = { ...errObj, [item]: 'Must be 9 digits.' }
        IsValid = false
      }
    } else if (item == 'ein' || item == 'trust_ein') {
      if (!numberRegex.test(data?.[item])) {
        errObj = { ...errObj, [item]: 'Must be numbers only' }
        IsValid = false
      }
      // else if (!isValid(data?.[item])) {
      //   errObj = { ...errObj, [item]: 'Invalid EIN number' }
      //   IsValid = false
      // }
    } else if (item == 'phone_number' || item == 'trustee_phone_number') {
      if (!validator.isMobilePhone(data?.[item])) {
        errObj = { ...errObj, [item]: 'Invalid Phone number' }
        IsValid = false
      }
      // let phoneNoRegex = /^\+\d{10,15}$/
      // if (!phoneNoRegex.test(data?.[item])) {
      //   errObj = { ...errObj, [item]: 'Invalid Phone number' }
      //   IsValid = false
      // }
    } else if (item == 'sources_of_wealth') {
      if (data?.[item]?.length < 1) {
        errObj = { ...errObj, [item]: 'Must contain numbers only' }
        IsValid = false
      }
    } else if (item == 'grantors_legal_name') {
      if (data?.[item]?.length == 1) {
        errObj = { ...errObj, [item]: 'Minimum 1 Grantors/Settlors legal name is required' }
        IsValid = false
      }
    } else if (item == 'dateOfBirth' || item == 'controllerDateOfBirth') {
      const enterDate = data?.[item]
      const date18yrsAgo = new Date()
      const date125yrsAgo = new Date()
      date18yrsAgo.setFullYear(date18yrsAgo.getFullYear() - 18)
      date125yrsAgo.setFullYear(date125yrsAgo.getFullYear() - 125)
      const valid =
        moment(formatDate(enterDate)).year() <= date18yrsAgo.getFullYear() &&
        moment(formatDate(enterDate)).year() >= date125yrsAgo.getFullYear()
      if (valid) {
        errObj = { ...errObj, [item]: '' }
        IsValid = true
      } else {
        errObj = { ...errObj, [item]: 'Invalid date' }
        IsValid = false
      }
    } else if (
      item == 'date_of_birth' ||
      item == 'trustee_date_of_birth'
      // || item == 'date_of_incorporation'
    ) {
      const enterDate = data?.[item]
      const date16yrsAgo = new Date()
      const date100yrsAgo = new Date()
      date16yrsAgo.setFullYear(date16yrsAgo.getFullYear() - 16)
      date100yrsAgo.setFullYear(date100yrsAgo.getFullYear() - 100)
      const valid =
        moment(formatDate(enterDate)).year() <= date16yrsAgo.getFullYear() &&
        moment(formatDate(enterDate)).year() >= date100yrsAgo.getFullYear()
      if (valid) {
        errObj = { ...errObj, [item]: '' }
        IsValid = true
      } else {
        errObj = { ...errObj, [item]: 'Invalid date' }
        IsValid = false
      }
    } else if (item == 'postalCode') {
      let postalCoderegx = /(^[0-9]{5}(?:-[0-9]{4})?$)/
      if (!postalCoderegx.test(data?.[item])) {
        errObj = { ...errObj, [item]: 'Invalid Zipcode' }
        IsValid = false
      }
    }
    const filterCheck = Object.values(errObj)?.filter(item => !!item)
    if (filterCheck?.length !== 0) {
      errorShow = true
    } else {
      errorShow = false
    }
  })

  if (Object.values(errObj)) return { errors_obj: errObj, status: IsValid, showError: errorShow }
}

/**
 * Validate input field on change and update the error object.
 *
 * @param {string} field - The name of the field being validated.
 * @param {any} value - The value of the field being validated.
 * @param {Object} error - The current error object.
 * @returns {Object} - Updated error object based on validation.
 */
export const onChangeValidation = (field, value, error) => {
  let erroObj = { ...error }
  if (value) {
    erroObj[field] = ''
  }
  if (value !== 0 && !value) {
    erroObj[field] = 'This field is required'
  }
  if (field == 'passport' && value) {
    erroObj['postalCode'] = ''
    erroObj['controllerState'] = ''
  }
  if (field == 'controllerPassport' && value) {
    erroObj['controllerZip_code'] = ''
    erroObj['controllerState'] = ''
  }
  if (
    (field == 'firstName' || field == 'lastName' || field == 'first_name' || field == 'last_name') &&
    // || field == 'businessName'
    value
  ) {
    let regx = /^[a-zA-Z]+$/
    if (!regx.test(value?.trimStart())) {
      erroObj[field] = `${
        // field == 'firstName' ? 'First' : field == 'lastName' ? 'Last' : 'Business'
        field == 'firstName' || field == 'first_name' ? 'First' : 'Last'
      } name must be character only`
    }
  }

  if ((field == 'controllerFirstName' || field == 'controllerLastName') && value) {
    let regx = /^[a-zA-Z]+$/
    if (!regx.test(value?.trimStart())) {
      erroObj[field] = `${
        field == 'controllerFirstName' ? 'Controller First' : 'Controller Last'
      } name must be character only`
    }
  }
  if (field == 'email' && value) {
    // let emailregx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z-]+(?:\.[a-zA-Z0-9-]+)*$/
    let emailregx = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/
    if (!emailregx.test(value)) {
      erroObj[field] = 'Invalid email address'
    }
  }
  if ((field == 'ein' || field == 'trust_ein' || field == 'fund_ein') && value) {
    if (!numberRegex.test(value)) {
      erroObj[field] = 'Must be numbers only'
    }
    // else if (!isValid(value)) {
    //   erroObj[field] = 'Invalid EIN number'
    // }
  }
  if ((field == 'postalCode' || field == 'controllerZip_code') && value) {
    let postalCoderegx = /(^[0-9]{5}(?:-[0-9]{4})?$)/
    if (!postalCoderegx.test(value)) {
      erroObj[field] = 'Invalid Postal code'
    }
  }

  if (
    (field == 'zipcode' ||
      field == 'trust_zipcode' ||
      field == 'trustee_zipcode' ||
      field == 'fund_zipcode' ||
      field == 'control_person_zipcode') &&
    value
  ) {
    let zipCoderegx = /^\d{5}(-\d{4})?$/
    if (!zipCoderegx.test(value)) {
      erroObj[field] = 'Invalid zip code'
    }
  }

  if (
    (field == 'phone_number' || field == 'trustee_phone_number' || field == 'control_person_phone_number') &&
    value
  ) {
    if (!validator.isMobilePhone(value)) {
      erroObj[field] = 'Invalid Phone number'
    }
    // let phoneregx = /^\+?\d{1,3}\)?\d{3,4}\)?\d{4}$/
    // if (!phoneregx.test(value)) {
    //   erroObj[field] = 'Invalid Phone number'
    // }
  }

  if ((field == 'dateOfBirth' || field == 'controllerDateOfBirth') && value) {
    const enterDate = new Date(formatDate(value)) // Parse the entered date
    const date18yrsAgo = new Date()
    const date125yrsAgo = new Date()
    date18yrsAgo.setFullYear(date18yrsAgo.getFullYear() - 18)
    date125yrsAgo.setFullYear(date125yrsAgo.getFullYear() - 125)
    const valid = enterDate <= date18yrsAgo && enterDate >= date125yrsAgo

    if (!valid) {
      erroObj[field] = 'Invalid Date of Birth'
    } else {
      erroObj[field] = '' // Clear the error if the date is valid
    }
  }
  if (
    (field == 'date_of_birth' || field == 'trustee_date_of_birth') &&
    //  || field == 'date_of_incorporation'
    value
  ) {
    const enterDate = new Date(formatDate(value)) // Parse the entered date
    const date16yrsAgo = new Date()
    const date100yrsAgo = new Date()
    date16yrsAgo.setFullYear(date16yrsAgo.getFullYear() - 16)
    date100yrsAgo.setFullYear(date100yrsAgo.getFullYear() - 100)
    const valid = enterDate <= date16yrsAgo && enterDate >= date100yrsAgo

    if (!valid) {
      erroObj[field] = 'Invalid Date of Birth'
    } else {
      erroObj[field] = '' // Clear the error if the date is valid
    }
  }
  return erroObj
}

export function areAllErrorFieldsEmpty(error) {
  const fieldValues = Object.values(error)

  return fieldValues.every(value => {
    return typeof value === 'undefined' || value === ''
  })
}

export const countyList = [
  {
    coty_name: 'Conecuh',
  },
  {
    coty_name: 'Escambia',
  },
  {
    coty_name: 'Franklin',
  },
  {
    coty_name: 'Jackson',
  },
  {
    coty_name: 'Cochise',
  },
  {
    coty_name: 'Marion',
  },
  {
    coty_name: 'Marin',
  },
  {
    coty_name: 'Kiowa',
  },
  {
    coty_name: 'La Plata',
  },
  {
    coty_name: 'Hardee',
  },
  {
    coty_name: 'Bartow',
  },
  {
    coty_name: 'Butts',
  },
  {
    coty_name: 'Houston',
  },
  {
    coty_name: 'Meriwether',
  },
  {
    coty_name: 'Rockdale',
  },
  {
    coty_name: 'Wilcox',
  },
  {
    coty_name: 'Jerome',
  },
  {
    coty_name: 'Jersey',
  },
  {
    coty_name: 'Stephenson',
  },
  {
    coty_name: 'Carroll',
  },
  {
    coty_name: 'Howard',
  },
  {
    coty_name: 'Jasper',
  },
  {
    coty_name: 'St. Joseph',
  },
  {
    coty_name: 'Scott',
  },
  {
    coty_name: 'Iowa',
  },
  {
    coty_name: 'Polk',
  },
  {
    coty_name: 'Ringgold',
  },
  {
    coty_name: 'Douglas',
  },
  {
    coty_name: 'Edwards',
  },
  {
    coty_name: 'McPherson',
  },
  {
    coty_name: 'Ness',
  },
  {
    coty_name: 'Ohio',
  },
  {
    coty_name: 'Waldo',
  },
  {
    coty_name: 'Arenac',
  },
  {
    coty_name: 'Gladwin',
  },
  {
    coty_name: 'Iron',
  },
  {
    coty_name: 'Livingston',
  },
  {
    coty_name: 'Sibley',
  },
  {
    coty_name: 'Stearns',
  },
  {
    coty_name: 'Benton',
  },
  {
    coty_name: 'Hancock',
  },
  {
    coty_name: 'Rankin',
  },
  {
    coty_name: 'Rolette',
  },
  {
    coty_name: 'Stutsman',
  },
  {
    coty_name: 'Erie',
  },
  {
    coty_name: 'Mercer',
  },
  {
    coty_name: 'Canadian',
  },
  {
    coty_name: 'Cherokee',
  },
  {
    coty_name: 'Harmon',
  },
  {
    coty_name: 'Stephens',
  },
  {
    coty_name: 'Grant',
  },
  {
    coty_name: 'Harney',
  },
  {
    coty_name: 'Berks',
  },
  {
    coty_name: 'Mifflin',
  },
  {
    coty_name: 'Schuylkill',
  },
  {
    coty_name: 'Kershaw',
  },
  {
    coty_name: 'Laurens',
  },
  {
    coty_name: 'Brule',
  },
  {
    coty_name: 'Dewey',
  },
  {
    coty_name: 'Gregory',
  },
  {
    coty_name: 'Hyde',
  },
  {
    coty_name: 'Sully',
  },
  {
    coty_name: 'Blount',
  },
  {
    coty_name: 'Fentress',
  },
  {
    coty_name: 'Grainger',
  },
  {
    coty_name: 'Robertson',
  },
  {
    coty_name: 'Collingsworth',
  },
  {
    coty_name: 'Irion',
  },
  {
    coty_name: 'Kimble',
  },
  {
    coty_name: 'Nolan',
  },
  {
    coty_name: 'Shackelford',
  },
  {
    coty_name: 'Starr',
  },
  {
    coty_name: 'Taylor',
  },
  {
    coty_name: 'Wise',
  },
  {
    coty_name: 'Botetourt',
  },
  {
    coty_name: 'Dinwiddie',
  },
  {
    coty_name: 'Fluvanna',
  },
  {
    coty_name: 'Greene',
  },
  {
    coty_name: 'Greensville',
  },
  {
    coty_name: 'Rockingham',
  },
  {
    coty_name: 'Asotin',
  },
  {
    coty_name: 'Chelan',
  },
  {
    coty_name: 'Columbia',
  },
  {
    coty_name: 'Florence',
  },
  {
    coty_name: 'Jefferson',
  },
  {
    coty_name: 'Laramie',
  },
  {
    coty_name: 'Platte',
  },
  {
    coty_name: 'Ray',
  },
  {
    coty_name: 'Washington',
  },
  {
    coty_name: 'Carbon',
  },
  {
    coty_name: 'Hill',
  },
  {
    coty_name: 'Teton',
  },
  {
    coty_name: 'Wheatland',
  },
  {
    coty_name: 'Scotts Bluff',
  },
  {
    coty_name: 'Gloucester',
  },
  {
    coty_name: 'Ocean',
  },
  {
    coty_name: 'Cibola',
  },
  {
    coty_name: 'McKinley',
  },
  {
    coty_name: 'Quay',
  },
  {
    coty_name: 'Allegany',
  },
  {
    coty_name: 'Montgomery',
  },
  {
    coty_name: 'Sullivan',
  },
  {
    coty_name: 'Alexander',
  },
  {
    coty_name: 'Avery',
  },
  {
    coty_name: 'Burke',
  },
  {
    coty_name: 'Colbert',
  },
  {
    coty_name: 'Yavapai',
  },
  {
    coty_name: 'Clay',
  },
  {
    coty_name: 'Fulton',
  },
  {
    coty_name: 'Woodruff',
  },
  {
    coty_name: 'El Dorado',
  },
  {
    coty_name: 'Humboldt',
  },
  {
    coty_name: 'Tehama',
  },
  {
    coty_name: 'Larimer',
  },
  {
    coty_name: 'Mineral',
  },
  {
    coty_name: 'Sedgwick',
  },
  {
    coty_name: 'Yuma',
  },
  {
    coty_name: 'Hamilton',
  },
  {
    coty_name: 'Colquitt',
  },
  {
    coty_name: 'Cook',
  },
  {
    coty_name: 'Mitchell',
  },
  {
    coty_name: 'Taliaferro',
  },
  {
    coty_name: 'Wheeler',
  },
  {
    coty_name: 'Ada',
  },
  {
    coty_name: 'Camas',
  },
  {
    coty_name: 'Bureau',
  },
  {
    coty_name: 'Henderson',
  },
  {
    coty_name: 'Adams',
  },
  {
    coty_name: 'Dearborn',
  },
  {
    coty_name: 'Huntington',
  },
  {
    coty_name: 'Lake',
  },
  {
    coty_name: 'Martin',
  },
  {
    coty_name: 'Wayne',
  },
  {
    coty_name: 'White',
  },
  {
    coty_name: 'Grundy',
  },
  {
    coty_name: 'Guthrie',
  },
  {
    coty_name: 'Lee',
  },
  {
    coty_name: 'Lucas',
  },
  {
    coty_name: 'Winneshiek',
  },
  {
    coty_name: 'Barton',
  },
  {
    coty_name: 'Hodgeman',
  },
  {
    coty_name: 'Jewell',
  },
  {
    coty_name: 'Rice',
  },
  {
    coty_name: 'Graves',
  },
  {
    coty_name: 'Harlan',
  },
  {
    coty_name: 'Garrett',
  },
  {
    coty_name: 'Alcona',
  },
  {
    coty_name: 'Baraga',
  },
  {
    coty_name: 'Cass',
  },
  {
    coty_name: 'Isabella',
  },
  {
    coty_name: 'Murray',
  },
  {
    coty_name: 'Norman',
  },
  {
    coty_name: 'Olmsted',
  },
  {
    coty_name: 'Wright',
  },
  {
    coty_name: 'Newton',
  },
  {
    coty_name: 'Tishomingo',
  },
  {
    coty_name: 'McLean',
  },
  {
    coty_name: 'Defiance',
  },
  {
    coty_name: 'Guernsey',
  },
  {
    coty_name: 'Hocking',
  },
  {
    coty_name: 'Medina',
  },
  {
    coty_name: 'Craig',
  },
  {
    coty_name: 'Grady',
  },
  {
    coty_name: 'Pushmataha',
  },
  {
    coty_name: 'Wagoner',
  },
  {
    coty_name: 'Curry',
  },
  {
    coty_name: 'Butler',
  },
  {
    coty_name: 'Lehigh',
  },
  {
    coty_name: 'Monroe',
  },
  {
    coty_name: 'Westmoreland',
  },
  {
    coty_name: 'Oglala Lakota',
  },
  {
    coty_name: 'Hickman',
  },
  {
    coty_name: 'Lauderdale',
  },
  {
    coty_name: 'Putnam',
  },
  {
    coty_name: 'Bailey',
  },
  {
    coty_name: 'Briscoe',
  },
  {
    coty_name: 'Carson',
  },
  {
    coty_name: 'Crosby',
  },
  {
    coty_name: 'Glasscock',
  },
  {
    coty_name: 'Karnes',
  },
  {
    coty_name: 'Loving',
  },
  {
    coty_name: 'Palo Pinto',
  },
  {
    coty_name: 'Schleicher',
  },
  {
    coty_name: 'Somervell',
  },
  {
    coty_name: 'Upton',
  },
  {
    coty_name: 'Waller',
  },
  {
    coty_name: 'Tooele',
  },
  {
    coty_name: 'Amherst',
  },
  {
    coty_name: 'Campbell',
  },
  {
    coty_name: 'Giles',
  },
  {
    coty_name: 'Cowlitz',
  },
  {
    coty_name: 'Barbour',
  },
  {
    coty_name: 'Randolph',
  },
  {
    coty_name: 'Marathon',
  },
  {
    coty_name: 'Outagamie',
  },
  {
    coty_name: 'Waushara',
  },
  {
    coty_name: 'Cooper',
  },
  {
    coty_name: 'Petroleum',
  },
  {
    coty_name: 'Saline',
  },
  {
    coty_name: 'Pershing',
  },
  {
    coty_name: 'Morris',
  },
  {
    coty_name: 'Sandoval',
  },
  {
    coty_name: 'Valencia',
  },
  {
    coty_name: 'Chemung',
  },
  {
    coty_name: 'Lewis',
  },
  {
    coty_name: 'Columbus',
  },
  {
    coty_name: 'Mobile',
  },
  {
    coty_name: 'Navajo',
  },
  {
    coty_name: 'Cleveland',
  },
  {
    coty_name: 'Lonoke',
  },
  {
    coty_name: 'Ouachita',
  },
  {
    coty_name: 'San Benito',
  },
  {
    coty_name: 'Broomfield',
  },
  {
    coty_name: 'Baker',
  },
  {
    coty_name: 'Bradford',
  },
  {
    coty_name: 'Brevard',
  },
  {
    coty_name: 'Gulf',
  },
  {
    coty_name: 'Indian River',
  },
  {
    coty_name: 'Bibb',
  },
  {
    coty_name: 'Chattooga',
  },
  {
    coty_name: 'Emanuel',
  },
  {
    coty_name: 'Glascock',
  },
  {
    coty_name: 'Oglethorpe',
  },
  {
    coty_name: 'Upson',
  },
  {
    coty_name: 'Walker',
  },
  {
    coty_name: 'Champaign',
  },
  {
    coty_name: 'Christian',
  },
  {
    coty_name: 'Henry',
  },
  {
    coty_name: 'Fountain',
  },
  {
    coty_name: 'Harrison',
  },
  {
    coty_name: 'Ripley',
  },
  {
    coty_name: 'Switzerland',
  },
  {
    coty_name: 'Clayton',
  },
  {
    coty_name: 'Davis',
  },
  {
    coty_name: 'Floyd',
  },
  {
    coty_name: 'Kossuth',
  },
  {
    coty_name: 'Muscatine',
  },
  {
    coty_name: 'Winnebago',
  },
  {
    coty_name: 'Decatur',
  },
  {
    coty_name: 'Nelson',
  },
  {
    coty_name: 'Trimble',
  },
  {
    coty_name: 'Androscoggin',
  },
  {
    coty_name: 'Sagadahoc',
  },
  {
    coty_name: 'Calhoun',
  },
  {
    coty_name: 'Luce',
  },
  {
    coty_name: 'Midland',
  },
  {
    coty_name: 'Becker',
  },
  {
    coty_name: 'Clearwater',
  },
  {
    coty_name: 'Hubbard',
  },
  {
    coty_name: 'Lake of the Woods',
  },
  {
    coty_name: 'Nicollet',
  },
  {
    coty_name: 'Otter Tail',
  },
  {
    coty_name: 'Pine',
  },
  {
    coty_name: 'Red Lake',
  },
  {
    coty_name: 'Claiborne',
  },
  {
    coty_name: 'Bollinger',
  },
  {
    coty_name: 'Clark',
  },
  {
    coty_name: 'Crawford',
  },
  {
    coty_name: 'Dade',
  },
  {
    coty_name: 'Pemiscot',
  },
  {
    coty_name: 'Arthur',
  },
  {
    coty_name: 'Boyd',
  },
  {
    coty_name: 'Kimball',
  },
  {
    coty_name: 'Warren',
  },
  {
    coty_name: 'Torrance',
  },
  {
    coty_name: 'Oneida',
  },
  {
    coty_name: 'Ulster',
  },
  {
    coty_name: 'Billings',
  },
  {
    coty_name: 'Walsh',
  },
  {
    coty_name: 'Allen',
  },
  {
    coty_name: 'Belmont',
  },
  {
    coty_name: 'Sandusky',
  },
  {
    coty_name: 'Shelby',
  },
  {
    coty_name: 'Summit',
  },
  {
    coty_name: 'Wood',
  },
  {
    coty_name: 'Marshall',
  },
  {
    coty_name: 'Tillamook',
  },
  {
    coty_name: 'Cumberland',
  },
  {
    coty_name: 'Dillon',
  },
  {
    coty_name: 'Spartanburg',
  },
  {
    coty_name: 'Brown',
  },
  {
    coty_name: 'Harding',
  },
  {
    coty_name: 'Potter',
  },
  {
    coty_name: 'Fayette',
  },
  {
    coty_name: 'Hardin',
  },
  {
    coty_name: 'Loudon',
  },
  {
    coty_name: 'Macon',
  },
  {
    coty_name: 'Burnet',
  },
  {
    coty_name: 'Cochran',
  },
  {
    coty_name: 'DeWitt',
  },
  {
    coty_name: 'Mills',
  },
  {
    coty_name: 'Montague',
  },
  {
    coty_name: 'Grand',
  },
  {
    coty_name: 'Amelia',
  },
  {
    coty_name: 'Bedford',
  },
  {
    coty_name: 'Caroline',
  },
  {
    coty_name: 'Chesterfield',
  },
  {
    coty_name: 'Dickenson',
  },
  {
    coty_name: 'Prince George',
  },
  {
    coty_name: 'Whatcom',
  },
  {
    coty_name: 'Kanawha',
  },
  {
    coty_name: 'Sawyer',
  },
  {
    coty_name: 'Trempealeau',
  },
  {
    coty_name: 'Bullock',
  },
  {
    coty_name: 'Dale',
  },
  {
    coty_name: 'Morgan',
  },
  {
    coty_name: 'Tuscaloosa',
  },
  {
    coty_name: 'Boone',
  },
  {
    coty_name: 'Napa',
  },
  {
    coty_name: 'Archuleta',
  },
  {
    coty_name: 'Custer',
  },
  {
    coty_name: 'Park',
  },
  {
    coty_name: 'Highlands',
  },
  {
    coty_name: 'Miami-Dade',
  },
  {
    coty_name: 'Charlton',
  },
  {
    coty_name: 'Long',
  },
  {
    coty_name: 'McDuffie',
  },
  {
    coty_name: 'Muscogee',
  },
  {
    coty_name: 'Honolulu',
  },
  {
    coty_name: 'Idaho',
  },
  {
    coty_name: 'Nez Perce',
  },
  {
    coty_name: 'Delaware',
  },
  {
    coty_name: 'Webster',
  },
  {
    coty_name: 'Worth',
  },
  {
    coty_name: 'Kingman',
  },
  {
    coty_name: 'Nemaha',
  },
  {
    coty_name: 'Osage',
  },
  {
    coty_name: 'Osborne',
  },
  {
    coty_name: 'Bell',
  },
  {
    coty_name: 'Crittenden',
  },
  {
    coty_name: 'Johnson',
  },
  {
    coty_name: 'Pulaski',
  },
  {
    coty_name: 'Mackinac',
  },
  {
    coty_name: 'Macomb',
  },
  {
    coty_name: 'Stevens',
  },
  {
    coty_name: 'Alcorn',
  },
  {
    coty_name: 'Covington',
  },
  {
    coty_name: 'Holmes',
  },
  {
    coty_name: 'Lamar',
  },
  {
    coty_name: 'Lincoln',
  },
  {
    coty_name: 'Mississippi',
  },
  {
    coty_name: 'New Madrid',
  },
  {
    coty_name: 'Blaine',
  },
  {
    coty_name: 'Cheyenne',
  },
  {
    coty_name: 'Holt',
  },
  {
    coty_name: 'Red Willow',
  },
  {
    coty_name: 'Cayuga',
  },
  {
    coty_name: 'Wyoming',
  },
  {
    coty_name: 'Madison',
  },
  {
    coty_name: 'Pasquotank',
  },
  {
    coty_name: 'Pender',
  },
  {
    coty_name: 'Divide',
  },
  {
    coty_name: 'Morton',
  },
  {
    coty_name: 'Steele',
  },
  {
    coty_name: 'Huron',
  },
  {
    coty_name: 'Coal',
  },
  {
    coty_name: 'Yamhill',
  },
  {
    coty_name: 'Huntingdon',
  },
  {
    coty_name: 'Snyder',
  },
  {
    coty_name: 'Susquehanna',
  },
  {
    coty_name: 'Union',
  },
  {
    coty_name: 'Dickson',
  },
  {
    coty_name: 'Hartley',
  },
  {
    coty_name: 'Kinney',
  },
  {
    coty_name: 'San Augustine',
  },
  {
    coty_name: 'Sherman',
  },
  {
    coty_name: 'Travis',
  },
  {
    coty_name: 'Charlotte',
  },
  {
    coty_name: 'Kitsap',
  },
  {
    coty_name: 'Roane',
  },
  {
    coty_name: 'Marinette',
  },
  {
    coty_name: 'Crook',
  },
  {
    coty_name: 'DeKalb',
  },
  {
    coty_name: 'Gila',
  },
  {
    coty_name: 'Bradley',
  },
  {
    coty_name: 'Desha',
  },
  {
    coty_name: 'Little River',
  },
  {
    coty_name: 'Santa Clara',
  },
  {
    coty_name: 'Kit Carson',
  },
  {
    coty_name: 'Pitkin',
  },
  {
    coty_name: 'Gilchrist',
  },
  {
    coty_name: 'Nassau',
  },
  {
    coty_name: 'Okaloosa',
  },
  {
    coty_name: 'Appling',
  },
  {
    coty_name: 'Coffee',
  },
  {
    coty_name: 'Rabun',
  },
  {
    coty_name: 'Spalding',
  },
  {
    coty_name: 'Twiggs',
  },
  {
    coty_name: 'Logan',
  },
  {
    coty_name: 'Spencer',
  },
  {
    coty_name: 'Warrick',
  },
  {
    coty_name: 'Dickinson',
  },
  {
    coty_name: 'Emmet',
  },
  {
    coty_name: 'Monona',
  },
  {
    coty_name: 'Woodbury',
  },
  {
    coty_name: 'Chase',
  },
  {
    coty_name: 'Harper',
  },
  {
    coty_name: 'Labette',
  },
  {
    coty_name: 'Norton',
  },
  {
    coty_name: 'Pottawatomie',
  },
  {
    coty_name: 'Woodson',
  },
  {
    coty_name: 'Anderson',
  },
  {
    coty_name: 'Berkshire',
  },
  {
    coty_name: 'Suffolk',
  },
  {
    coty_name: 'Berrien',
  },
  {
    coty_name: 'Branch',
  },
  {
    coty_name: 'Fillmore',
  },
  {
    coty_name: 'Pipestone',
  },
  {
    coty_name: 'Pope',
  },
  {
    coty_name: 'Ramsey',
  },
  {
    coty_name: 'Roseau',
  },
  {
    coty_name: 'Sherburne',
  },
  {
    coty_name: 'Todd',
  },
  {
    coty_name: 'Issaquena',
  },
  {
    coty_name: 'Tippah',
  },
  {
    coty_name: 'Golden Valley',
  },
  {
    coty_name: 'Traill',
  },
  {
    coty_name: 'Preble',
  },
  {
    coty_name: 'Tuscarawas',
  },
  {
    coty_name: 'Adair',
  },
  {
    coty_name: 'Choctaw',
  },
  {
    coty_name: 'Greer',
  },
  {
    coty_name: 'Noble',
  },
  {
    coty_name: 'Texas',
  },
  {
    coty_name: 'Beaver',
  },
  {
    coty_name: 'Juniata',
  },
  {
    coty_name: 'Newberry',
  },
  {
    coty_name: 'Pickens',
  },
  {
    coty_name: 'Fall River',
  },
  {
    coty_name: 'Jerauld',
  },
  {
    coty_name: 'Meade',
  },
  {
    coty_name: 'Roberts',
  },
  {
    coty_name: 'Cocke',
  },
  {
    coty_name: 'Pickett',
  },
  {
    coty_name: 'Trousdale',
  },
  {
    coty_name: 'Van Buren',
  },
  {
    coty_name: 'Foard',
  },
  {
    coty_name: 'Fort Bend',
  },
  {
    coty_name: 'Lamb',
  },
  {
    coty_name: 'Leon',
  },
  {
    coty_name: 'Reeves',
  },
  {
    coty_name: 'Rusk',
  },
  {
    coty_name: 'Uvalde',
  },
  {
    coty_name: 'Cache',
  },
  {
    coty_name: 'Daggett',
  },
  {
    coty_name: 'Garfield',
  },
  {
    coty_name: 'Juab',
  },
  {
    coty_name: 'Sevier',
  },
  {
    coty_name: 'Albemarle',
  },
  {
    coty_name: 'Isle of Wight',
  },
  {
    coty_name: 'Richmond',
  },
  {
    coty_name: 'Grays Harbor',
  },
  {
    coty_name: 'Sublette',
  },
  {
    coty_name: 'Carter',
  },
  {
    coty_name: 'Vernon',
  },
  {
    coty_name: 'Silver Bow',
  },
  {
    coty_name: 'Gage',
  },
  {
    coty_name: 'Merrimack',
  },
  {
    coty_name: 'Taos',
  },
  {
    coty_name: 'Kings',
  },
  {
    coty_name: 'Oswego',
  },
  {
    coty_name: 'Queens',
  },
  {
    coty_name: 'Tompkins',
  },
  {
    coty_name: 'Cabarrus',
  },
  {
    coty_name: 'Lafayette',
  },
  {
    coty_name: 'McCone',
  },
  {
    coty_name: 'Powell',
  },
  {
    coty_name: 'Dixon',
  },
  {
    coty_name: 'Hall',
  },
  {
    coty_name: 'Perkins',
  },
  {
    coty_name: 'Richardson',
  },
  {
    coty_name: 'Eureka',
  },
  {
    coty_name: 'Belknap',
  },
  {
    coty_name: 'Mora',
  },
  {
    coty_name: 'Alamance',
  },
  {
    coty_name: 'Camden',
  },
  {
    coty_name: 'Gaston',
  },
  {
    coty_name: 'Orange',
  },
  {
    coty_name: 'Stokes',
  },
  {
    coty_name: 'Benson',
  },
  {
    coty_name: 'Wells',
  },
  {
    coty_name: 'Perry',
  },
  {
    coty_name: 'Atoka',
  },
  {
    coty_name: 'Payne',
  },
  {
    coty_name: 'Lane',
  },
  {
    coty_name: 'Cameron',
  },
  {
    coty_name: 'Charleston',
  },
  {
    coty_name: 'Codington',
  },
  {
    coty_name: 'Hutchinson',
  },
  {
    coty_name: 'Turner',
  },
  {
    coty_name: 'Humphreys',
  },
  {
    coty_name: 'Chambers',
  },
  {
    coty_name: 'Coleman',
  },
  {
    coty_name: 'Crockett',
  },
  {
    coty_name: 'Duval',
  },
  {
    coty_name: 'Hardeman',
  },
  {
    coty_name: 'Jones',
  },
  {
    coty_name: 'Lavaca',
  },
  {
    coty_name: 'Mason',
  },
  {
    coty_name: 'Rains',
  },
  {
    coty_name: 'Willacy',
  },
  {
    coty_name: 'Emery',
  },
  {
    coty_name: 'Millard',
  },
  {
    coty_name: 'San Juan',
  },
  {
    coty_name: 'Henrico',
  },
  {
    coty_name: 'Mathews',
  },
  {
    coty_name: 'Nicholas',
  },
  {
    coty_name: 'Pendleton',
  },
  {
    coty_name: 'Chippewa',
  },
  {
    coty_name: 'Dane',
  },
  {
    coty_name: 'Price',
  },
  {
    coty_name: 'Gilliam',
  },
  {
    coty_name: 'Josephine',
  },
  {
    coty_name: 'Hale',
  },
  {
    coty_name: 'Cross',
  },
  {
    coty_name: 'Santa Cruz',
  },
  {
    coty_name: 'Alamosa',
  },
  {
    coty_name: 'Delta',
  },
  {
    coty_name: 'Huerfano',
  },
  {
    coty_name: 'St. Lucie',
  },
  {
    coty_name: 'Sumter',
  },
  {
    coty_name: 'Barrow',
  },
  {
    coty_name: 'Clarke',
  },
  {
    coty_name: 'Telfair',
  },
  {
    coty_name: 'Wilkinson',
  },
  {
    coty_name: 'Canyon',
  },
  {
    coty_name: 'Kendall',
  },
  {
    coty_name: 'Ida',
  },
  {
    coty_name: 'Wichita',
  },
  {
    coty_name: 'Jessamine',
  },
  {
    coty_name: 'McCracken',
  },
  {
    coty_name: 'Rowan',
  },
  {
    coty_name: 'Ingham',
  },
  {
    coty_name: 'Iosco',
  },
  {
    coty_name: 'Lapeer',
  },
  {
    coty_name: 'Lac qui Parle',
  },
  {
    coty_name: 'Morrison',
  },
  {
    coty_name: 'Wilkin',
  },
  {
    coty_name: 'Yellow Medicine',
  },
  {
    coty_name: 'Sharkey',
  },
  {
    coty_name: 'Stone',
  },
  {
    coty_name: 'Walthall',
  },
  {
    coty_name: 'Winston',
  },
  {
    coty_name: 'Lawrence',
  },
  {
    coty_name: 'Poinsett',
  },
  {
    coty_name: 'St. Francis',
  },
  {
    coty_name: 'Alachua',
  },
  {
    coty_name: 'Broward',
  },
  {
    coty_name: 'Coweta',
  },
  {
    coty_name: 'Gordon',
  },
  {
    coty_name: 'Edgar',
  },
  {
    coty_name: 'Tazewell',
  },
  {
    coty_name: 'Parke',
  },
  {
    coty_name: 'Rush',
  },
  {
    coty_name: 'Vigo',
  },
  {
    coty_name: 'Poweshiek',
  },
  {
    coty_name: 'Edmonson',
  },
  {
    coty_name: 'Owsley',
  },
  {
    coty_name: 'Pike',
  },
  {
    coty_name: 'York',
  },
  {
    coty_name: 'Worcester',
  },
  {
    coty_name: 'Oceana',
  },
  {
    coty_name: 'DeSoto',
  },
  {
    coty_name: 'Forrest',
  },
  {
    coty_name: 'Oktibbeha',
  },
  {
    coty_name: 'Tate',
  },
  {
    coty_name: 'Tunica',
  },
  {
    coty_name: 'Cavalier',
  },
  {
    coty_name: 'Grand Forks',
  },
  {
    coty_name: 'Stark',
  },
  {
    coty_name: 'Beckham',
  },
  {
    coty_name: 'Kay',
  },
  {
    coty_name: 'Kingfisher',
  },
  {
    coty_name: 'McCurtain',
  },
  {
    coty_name: 'McKean',
  },
  {
    coty_name: 'Venango',
  },
  {
    coty_name: 'Allendale',
  },
  {
    coty_name: 'Marlboro',
  },
  {
    coty_name: 'Butte',
  },
  {
    coty_name: 'Kingsbury',
  },
  {
    coty_name: 'Walworth',
  },
  {
    coty_name: 'Yankton',
  },
  {
    coty_name: 'Cole',
  },
  {
    coty_name: 'Big Horn',
  },
  {
    coty_name: 'Dodge',
  },
  {
    coty_name: 'Rio Arriba',
  },
  {
    coty_name: 'St. Lawrence',
  },
  {
    coty_name: 'Bertie',
  },
  {
    coty_name: 'Buncombe',
  },
  {
    coty_name: 'Mecklenburg',
  },
  {
    coty_name: 'Pamlico',
  },
  {
    coty_name: 'Robeson',
  },
  {
    coty_name: 'Burleigh',
  },
  {
    coty_name: 'Deaf Smith',
  },
  {
    coty_name: 'Real',
  },
  {
    coty_name: 'Sterling',
  },
  {
    coty_name: 'Yoakum',
  },
  {
    coty_name: 'Box Elder',
  },
  {
    coty_name: 'Buckingham',
  },
  {
    coty_name: 'Pacific',
  },
  {
    coty_name: 'Monongalia',
  },
  {
    coty_name: 'Raleigh',
  },
  {
    coty_name: 'Waupaca',
  },
  {
    coty_name: 'Sioux',
  },
  {
    coty_name: 'Towner',
  },
  {
    coty_name: 'Cuyahoga',
  },
  {
    coty_name: 'Gallia',
  },
  {
    coty_name: 'Knox',
  },
  {
    coty_name: 'Richland',
  },
  {
    coty_name: 'Scioto',
  },
  {
    coty_name: 'McClain',
  },
  {
    coty_name: 'Major',
  },
  {
    coty_name: 'Cambria',
  },
  {
    coty_name: 'Bristol',
  },
  {
    coty_name: 'Greenville',
  },
  {
    coty_name: 'Hamlin',
  },
  {
    coty_name: 'Lyman',
  },
  {
    coty_name: 'Hamblen',
  },
  {
    coty_name: 'Borden',
  },
  {
    coty_name: 'Comal',
  },
  {
    coty_name: 'Comanche',
  },
  {
    coty_name: 'Frio',
  },
  {
    coty_name: 'Garza',
  },
  {
    coty_name: 'Gray',
  },
  {
    coty_name: 'Kerr',
  },
  {
    coty_name: 'Nueces',
  },
  {
    coty_name: 'Ochiltree',
  },
  {
    coty_name: 'Terry',
  },
  {
    coty_name: 'Zavala',
  },
  {
    coty_name: 'Sanpete',
  },
  {
    coty_name: 'Charles City',
  },
  {
    coty_name: 'Nottoway',
  },
  {
    coty_name: 'Island',
  },
  {
    coty_name: 'Green',
  },
  {
    coty_name: 'Green Lake',
  },
  {
    coty_name: 'Langlade',
  },
  {
    coty_name: 'Pepin',
  },
  {
    coty_name: 'Stoddard',
  },
  {
    coty_name: 'Cascade',
  },
  {
    coty_name: 'Glacier',
  },
  {
    coty_name: 'Liberty',
  },
  {
    coty_name: 'Rosebud',
  },
  {
    coty_name: 'Lancaster',
  },
  {
    coty_name: 'Cape May',
  },
  {
    coty_name: 'Sierra',
  },
  {
    coty_name: 'Rockland',
  },
  {
    coty_name: 'Currituck',
  },
  {
    coty_name: 'Davidson',
  },
  {
    coty_name: 'Davie',
  },
  {
    coty_name: 'Granville',
  },
  {
    coty_name: 'Amador',
  },
  {
    coty_name: 'Mono',
  },
  {
    coty_name: 'Eagle',
  },
  {
    coty_name: 'Mesa',
  },
  {
    coty_name: 'New Castle',
  },
  {
    coty_name: 'Brantley',
  },
  {
    coty_name: 'Dooly',
  },
  {
    coty_name: 'Echols',
  },
  {
    coty_name: 'Paulding',
  },
  {
    coty_name: 'Seminole',
  },
  {
    coty_name: 'Piatt',
  },
  {
    coty_name: 'Gove',
  },
  {
    coty_name: 'Greenwood',
  },
  {
    coty_name: 'Gallatin',
  },
  {
    coty_name: 'Woodford',
  },
  {
    coty_name: 'Dakota',
  },
  {
    coty_name: 'Kanabec',
  },
  {
    coty_name: 'George',
  },
  {
    coty_name: 'Beaverhead',
  },
  {
    coty_name: 'Valley',
  },
  {
    coty_name: 'Yellowstone',
  },
  {
    coty_name: 'Antelope',
  },
  {
    coty_name: 'Dawson',
  },
  {
    coty_name: 'Nance',
  },
  {
    coty_name: 'Nuckolls',
  },
  {
    coty_name: 'Storey',
  },
  {
    coty_name: 'Otero',
  },
  {
    coty_name: 'Chatham',
  },
  {
    coty_name: 'Iredell',
  },
  {
    coty_name: 'Johnston',
  },
  {
    coty_name: 'Moore',
  },
  {
    coty_name: 'Yadkin',
  },
  {
    coty_name: 'Emmons',
  },
  {
    coty_name: 'Pickaway',
  },
  {
    coty_name: 'Le Flore',
  },
  {
    coty_name: 'Umatilla',
  },
  {
    coty_name: 'Saluda',
  },
  {
    coty_name: 'Brazos',
  },
  {
    coty_name: 'Burleson',
  },
  {
    coty_name: 'Kent',
  },
  {
    coty_name: 'Lubbock',
  },
  {
    coty_name: 'Parmer',
  },
  {
    coty_name: 'Wilson',
  },
  {
    coty_name: 'Zapata',
  },
  {
    coty_name: 'Piute',
  },
  {
    coty_name: 'Grand Isle',
  },
  {
    coty_name: 'Fauquier',
  },
  {
    coty_name: 'Frederick',
  },
  {
    coty_name: 'Halifax',
  },
  {
    coty_name: 'Highland',
  },
  {
    coty_name: 'New Kent',
  },
  {
    coty_name: 'Shenandoah',
  },
  {
    coty_name: 'Sussex',
  },
  {
    coty_name: 'Klickitat',
  },
  {
    coty_name: 'Skamania',
  },
  {
    coty_name: 'Yakima',
  },
  {
    coty_name: 'Burnett',
  },
  {
    coty_name: 'Forest',
  },
  {
    coty_name: 'Kewaunee',
  },
  {
    coty_name: 'Menominee',
  },
  {
    coty_name: 'Sauk',
  },
  {
    coty_name: 'Crenshaw',
  },
  {
    coty_name: 'Yell',
  },
  {
    coty_name: 'Contra Costa',
  },
  {
    coty_name: 'Modoc',
  },
  {
    coty_name: 'Riverside',
  },
  {
    coty_name: 'Bent',
  },
  {
    coty_name: 'Fremont',
  },
  {
    coty_name: 'Santa Rosa',
  },
  {
    coty_name: 'Walton',
  },
  {
    coty_name: 'Baldwin',
  },
  {
    coty_name: 'Cobb',
  },
  {
    coty_name: 'Dougherty',
  },
  {
    coty_name: 'Hart',
  },
  {
    coty_name: 'Tift',
  },
  {
    coty_name: 'Effingham',
  },
  {
    coty_name: 'Jo Daviess',
  },
  {
    coty_name: 'LaPorte',
  },
  {
    coty_name: 'Starke',
  },
  {
    coty_name: 'Louisa',
  },
  {
    coty_name: 'Kalamazoo',
  },
  {
    coty_name: 'Montcalm',
  },
  {
    coty_name: 'Newaygo',
  },
  {
    coty_name: 'Shiawassee',
  },
  {
    coty_name: 'Kandiyohi',
  },
  {
    coty_name: 'Panola',
  },
  {
    coty_name: 'Andrew',
  },
  {
    coty_name: 'Tallapoosa',
  },
  {
    coty_name: 'Hempstead',
  },
  {
    coty_name: 'Colusa',
  },
  {
    coty_name: 'Inyo',
  },
  {
    coty_name: 'Yuba',
  },
  {
    coty_name: 'Clear Creek',
  },
  {
    coty_name: 'Moffat',
  },
  {
    coty_name: 'Saguache',
  },
  {
    coty_name: 'Suwannee',
  },
  {
    coty_name: 'Talbot',
  },
  {
    coty_name: 'Bonneville',
  },
  {
    coty_name: 'Sangamon',
  },
  {
    coty_name: 'Osceola',
  },
  {
    coty_name: 'Palo Alto',
  },
  {
    coty_name: 'Pottawattamie',
  },
  {
    coty_name: 'Cloud',
  },
  {
    coty_name: 'Coffey',
  },
  {
    coty_name: 'Charles',
  },
  {
    coty_name: 'Nantucket',
  },
  {
    coty_name: 'Bay',
  },
  {
    coty_name: 'Blue Earth',
  },
  {
    coty_name: 'Goodhue',
  },
  {
    coty_name: 'Amite',
  },
  {
    coty_name: 'Leflore',
  },
  {
    coty_name: 'Quitman',
  },
  {
    coty_name: 'Toole',
  },
  {
    coty_name: 'Elko',
  },
  {
    coty_name: 'Esmeralda',
  },
  {
    coty_name: 'Nye',
  },
  {
    coty_name: 'Catron',
  },
  {
    coty_name: 'Gates',
  },
  {
    coty_name: 'Latimer',
  },
  {
    coty_name: 'Chester',
  },
  {
    coty_name: 'Edmunds',
  },
  {
    coty_name: 'Moody',
  },
  {
    coty_name: 'Rutherford',
  },
  {
    coty_name: 'Falls',
  },
  {
    coty_name: 'Hockley',
  },
  {
    coty_name: 'Kenedy',
  },
  {
    coty_name: 'King',
  },
  {
    coty_name: 'Presidio',
  },
  {
    coty_name: 'Swisher',
  },
  {
    coty_name: 'Uintah',
  },
  {
    coty_name: 'Windham',
  },
  {
    coty_name: 'Snohomish',
  },
  {
    coty_name: 'Ashland',
  },
  {
    coty_name: 'Juneau',
  },
  {
    coty_name: 'Manitowoc',
  },
  {
    coty_name: 'Clinton',
  },
  {
    coty_name: 'McDonald',
  },
  {
    coty_name: 'Broadwater',
  },
  {
    coty_name: 'Wilkes',
  },
  {
    coty_name: 'Chilton',
  },
  {
    coty_name: 'Elmore',
  },
  {
    coty_name: 'Santa Barbara',
  },
  {
    coty_name: 'Costilla',
  },
  {
    coty_name: 'Gadsden',
  },
  {
    coty_name: 'Lanier',
  },
  {
    coty_name: 'Boise',
  },
  {
    coty_name: 'McHenry',
  },
  {
    coty_name: 'Moultrie',
  },
  {
    coty_name: 'Ford',
  },
  {
    coty_name: 'Caldwell',
  },
  {
    coty_name: 'Middlesex',
  },
  {
    coty_name: 'Barry',
  },
  {
    coty_name: 'Ontonagon',
  },
  {
    coty_name: 'Ottawa',
  },
  {
    coty_name: 'Sanilac',
  },
  {
    coty_name: 'Washtenaw',
  },
  {
    coty_name: 'Aitkin',
  },
  {
    coty_name: 'Le Sueur',
  },
  {
    coty_name: 'Simpson',
  },
  {
    coty_name: 'Nodaway',
  },
  {
    coty_name: 'Ravalli',
  },
  {
    coty_name: 'Cuming',
  },
  {
    coty_name: 'Grafton',
  },
  {
    coty_name: 'Monmouth',
  },
  {
    coty_name: 'Guadalupe',
  },
  {
    coty_name: 'Essex',
  },
  {
    coty_name: 'Durham',
  },
  {
    coty_name: 'McDowell',
  },
  {
    coty_name: 'Wake',
  },
  {
    coty_name: 'Sheridan',
  },
  {
    coty_name: 'Williams',
  },
  {
    coty_name: 'Columbiana',
  },
  {
    coty_name: 'Tillman',
  },
  {
    coty_name: 'Wasco',
  },
  {
    coty_name: 'Clearfield',
  },
  {
    coty_name: 'Hanson',
  },
  {
    coty_name: 'Bandera',
  },
  {
    coty_name: 'Freestone',
  },
  {
    coty_name: 'Kaufman',
  },
  {
    coty_name: 'Lampasas',
  },
  {
    coty_name: 'Addison',
  },
  {
    coty_name: 'Orleans',
  },
  {
    coty_name: 'Goochland',
  },
  {
    coty_name: 'Northumberland',
  },
  {
    coty_name: 'Page',
  },
  {
    coty_name: 'Skagit',
  },
  {
    coty_name: 'Calumet',
  },
  {
    coty_name: 'Ozaukee',
  },
  {
    coty_name: 'Natrona',
  },
  {
    coty_name: 'Autauga',
  },
  {
    coty_name: 'Apache',
  },
  {
    coty_name: 'San Bernardino',
  },
  {
    coty_name: 'Collier',
  },
  {
    coty_name: 'Crisp',
  },
  {
    coty_name: 'Hawaii',
  },
  {
    coty_name: 'Black Hawk',
  },
  {
    coty_name: 'Bremer',
  },
  {
    coty_name: 'Bath',
  },
  {
    coty_name: 'Trigg',
  },
  {
    coty_name: 'Kennebec',
  },
  {
    coty_name: 'Harford',
  },
  {
    coty_name: 'Barnstable',
  },
  {
    coty_name: 'Missaukee',
  },
  {
    coty_name: 'Oscoda',
  },
  {
    coty_name: 'Wexford',
  },
  {
    coty_name: 'Cottonwood',
  },
  {
    coty_name: 'McLeod',
  },
  {
    coty_name: 'Renville',
  },
  {
    coty_name: 'Coahoma',
  },
  {
    coty_name: 'Itawamba',
  },
  {
    coty_name: 'Pettis',
  },
  {
    coty_name: 'Schuyler',
  },
  {
    coty_name: 'Fergus',
  },
  {
    coty_name: 'Sanders',
  },
  {
    coty_name: 'Colfax',
  },
  {
    coty_name: 'Gosper',
  },
  {
    coty_name: 'Chaves',
  },
  {
    coty_name: 'Schoharie',
  },
  {
    coty_name: 'LaMoure',
  },
  {
    coty_name: 'Ransom',
  },
  {
    coty_name: 'Hughes',
  },
  {
    coty_name: 'Love',
  },
  {
    coty_name: 'Pontotoc',
  },
  {
    coty_name: 'Rogers',
  },
  {
    coty_name: 'Hood River',
  },
  {
    coty_name: 'Centre',
  },
  {
    coty_name: 'Georgetown',
  },
  {
    coty_name: 'Cooke',
  },
  {
    coty_name: 'Gaines',
  },
  {
    coty_name: 'Jeff Davis',
  },
  {
    coty_name: 'Limestone',
  },
  {
    coty_name: 'Smith',
  },
  {
    coty_name: 'Upshur',
  },
  {
    coty_name: 'Victoria',
  },
  {
    coty_name: 'Winkler',
  },
  {
    coty_name: 'Rutland',
  },
  {
    coty_name: 'Stafford',
  },
  {
    coty_name: 'Cabell',
  },
  {
    coty_name: 'Gilmer',
  },
  {
    coty_name: 'Portage',
  },
  {
    coty_name: 'Goshen',
  },
  {
    coty_name: 'Brunswick',
  },
  {
    coty_name: 'Edgecombe',
  },
  {
    coty_name: 'Transylvania',
  },
  {
    coty_name: 'Cape Girardeau',
  },
  {
    coty_name: 'Scotland',
  },
  {
    coty_name: 'Otoe',
  },
  {
    coty_name: 'Stanton',
  },
  {
    coty_name: 'Lander',
  },
  {
    coty_name: 'Somerset',
  },
  {
    coty_name: 'Genesee',
  },
  {
    coty_name: 'Hoke',
  },
  {
    coty_name: 'Dunn',
  },
  {
    coty_name: 'Griggs',
  },
  {
    coty_name: 'Pierce',
  },
  {
    coty_name: 'Morrow',
  },
  {
    coty_name: 'McIntosh',
  },
  {
    coty_name: 'Clatsop',
  },
  {
    coty_name: 'Clarion',
  },
  {
    coty_name: 'Clarendon',
  },
  {
    coty_name: 'Corson',
  },
  {
    coty_name: 'Davison',
  },
  {
    coty_name: 'Deuel',
  },
  {
    coty_name: 'Camp',
  },
  {
    coty_name: 'Cottle',
  },
  {
    coty_name: 'Culberson',
  },
  {
    coty_name: 'Hemphill',
  },
  {
    coty_name: 'Jim Hogg',
  },
  {
    coty_name: 'Sutton',
  },
  {
    coty_name: 'Terrell',
  },
  {
    coty_name: 'Titus',
  },
  {
    coty_name: 'King and Queen',
  },
  {
    coty_name: 'Oconto',
  },
  {
    coty_name: 'St. Croix',
  },
  {
    coty_name: 'Ashley',
  },
  {
    coty_name: 'Chicot',
  },
  {
    coty_name: 'Sharp',
  },
  {
    coty_name: 'Alpine',
  },
  {
    coty_name: 'Chaffee',
  },
  {
    coty_name: 'Hendry',
  },
  {
    coty_name: 'Hillsborough',
  },
  {
    coty_name: 'Bacon',
  },
  {
    coty_name: 'Lumpkin',
  },
  {
    coty_name: 'Screven',
  },
  {
    coty_name: 'Thomas',
  },
  {
    coty_name: 'Minidoka',
  },
  {
    coty_name: 'Twin Falls',
  },
  {
    coty_name: 'Coles',
  },
  {
    coty_name: 'Jennings',
  },
  {
    coty_name: 'Posey',
  },
  {
    coty_name: 'Tippecanoe',
  },
  {
    coty_name: 'Allamakee',
  },
  {
    coty_name: 'Sac',
  },
  {
    coty_name: 'Bourbon',
  },
  {
    coty_name: 'Oldham',
  },
  {
    coty_name: "Queen Anne's",
  },
  {
    coty_name: 'Grand Traverse',
  },
  {
    coty_name: 'Keweenaw',
  },
  {
    coty_name: 'Leelanau',
  },
  {
    coty_name: 'Montmorency',
  },
  {
    coty_name: 'Schoolcraft',
  },
  {
    coty_name: 'Koochiching',
  },
  {
    coty_name: 'Pennington',
  },
  {
    coty_name: 'St. Louis',
  },
  {
    coty_name: 'Waseca',
  },
  {
    coty_name: 'Austin',
  },
  {
    coty_name: 'Bosque',
  },
  {
    coty_name: 'Denton',
  },
  {
    coty_name: 'Ector',
  },
  {
    coty_name: 'Ellis',
  },
  {
    coty_name: 'Lipscomb',
  },
  {
    coty_name: 'Randall',
  },
  {
    coty_name: 'Rockwall',
  },
  {
    coty_name: 'Lamoille',
  },
  {
    coty_name: 'Roanoke',
  },
  {
    coty_name: 'Clallam',
  },
  {
    coty_name: 'Wahkiakum',
  },
  {
    coty_name: 'Pembina',
  },
  {
    coty_name: 'Wyandot',
  },
  {
    coty_name: 'Mayes',
  },
  {
    coty_name: 'Dauphin',
  },
  {
    coty_name: 'Luzerne',
  },
  {
    coty_name: 'McCormick',
  },
  {
    coty_name: 'Hand',
  },
  {
    coty_name: 'Sanborn',
  },
  {
    coty_name: 'Linn',
  },
  {
    coty_name: 'Maries',
  },
  {
    coty_name: 'Shannon',
  },
  {
    coty_name: 'Prairie',
  },
  {
    coty_name: 'Roosevelt',
  },
  {
    coty_name: 'Kearney',
  },
  {
    coty_name: 'Herkimer',
  },
  {
    coty_name: 'Surry',
  },
  {
    coty_name: 'Coosa',
  },
  {
    coty_name: 'Sebastian',
  },
  {
    coty_name: 'Del Norte',
  },
  {
    coty_name: 'Baca',
  },
  {
    coty_name: 'Ouray',
  },
  {
    coty_name: 'Pueblo',
  },
  {
    coty_name: 'Rio Grande',
  },
  {
    coty_name: 'Routt',
  },
  {
    coty_name: 'Harris',
  },
  {
    coty_name: 'Miller',
  },
  {
    coty_name: 'Tattnall',
  },
  {
    coty_name: 'Bannock',
  },
  {
    coty_name: 'Bonner',
  },
  {
    coty_name: 'Bond',
  },
  {
    coty_name: 'Wabash',
  },
  {
    coty_name: 'Kosciusko',
  },
  {
    coty_name: 'Owen',
  },
  {
    coty_name: 'Vermillion',
  },
  {
    coty_name: 'Appanoose',
  },
  {
    coty_name: 'Ellsworth',
  },
  {
    coty_name: 'Greeley',
  },
  {
    coty_name: 'Phillips',
  },
  {
    coty_name: 'Pratt',
  },
  {
    coty_name: 'Riley',
  },
  {
    coty_name: 'Carlisle',
  },
  {
    coty_name: 'Fleming',
  },
  {
    coty_name: 'Greenup',
  },
  {
    coty_name: 'Lyon',
  },
  {
    coty_name: 'Piscataquis',
  },
  {
    coty_name: 'Chisago',
  },
  {
    coty_name: 'Mahnomen',
  },
  {
    coty_name: 'Rock',
  },
  {
    coty_name: 'Copiah',
  },
  {
    coty_name: 'Baxter',
  },
  {
    coty_name: 'Hot Spring',
  },
  {
    coty_name: 'El Paso',
  },
  {
    coty_name: 'Glades',
  },
  {
    coty_name: 'Sarasota',
  },
  {
    coty_name: 'Clinch',
  },
  {
    coty_name: 'Gwinnett',
  },
  {
    coty_name: 'Gem',
  },
  {
    coty_name: 'Payette',
  },
  {
    coty_name: 'Shoshone',
  },
  {
    coty_name: 'Macoupin',
  },
  {
    coty_name: 'St. Clair',
  },
  {
    coty_name: 'Vanderburgh',
  },
  {
    coty_name: 'Wapello',
  },
  {
    coty_name: 'Chautauqua',
  },
  {
    coty_name: 'Elk',
  },
  {
    coty_name: 'Haskell',
  },
  {
    coty_name: 'Pawnee',
  },
  {
    coty_name: 'Boyle',
  },
  {
    coty_name: 'Daviess',
  },
  {
    coty_name: 'Grayson',
  },
  {
    coty_name: 'Larue',
  },
  {
    coty_name: 'Letcher',
  },
  {
    coty_name: 'Whitley',
  },
  {
    coty_name: 'Charlevoix',
  },
  {
    coty_name: 'Gogebic',
  },
  {
    coty_name: 'Crow Wing',
  },
  {
    coty_name: 'Mille Lacs',
  },
  {
    coty_name: 'Mower',
  },
  {
    coty_name: 'Tallahatchie',
  },
  {
    coty_name: 'Dickey',
  },
  {
    coty_name: 'Pittsburg',
  },
  {
    coty_name: 'Coos',
  },
  {
    coty_name: 'Deschutes',
  },
  {
    coty_name: 'Armstrong',
  },
  {
    coty_name: 'Bamberg',
  },
  {
    coty_name: 'Fairfield',
  },
  {
    coty_name: 'Oconee',
  },
  {
    coty_name: 'Brookings',
  },
  {
    coty_name: 'Faulk',
  },
  {
    coty_name: 'Gibson',
  },
  {
    coty_name: 'Obion',
  },
  {
    coty_name: 'Andrews',
  },
  {
    coty_name: 'Brazoria',
  },
  {
    coty_name: 'Collin',
  },
  {
    coty_name: 'McCulloch',
  },
  {
    coty_name: 'Nacogdoches',
  },
  {
    coty_name: 'Reagan',
  },
  {
    coty_name: 'Red River',
  },
  {
    coty_name: 'Utah',
  },
  {
    coty_name: 'Weber',
  },
  {
    coty_name: 'Augusta',
  },
  {
    coty_name: 'Culpeper',
  },
  {
    coty_name: 'Spotsylvania',
  },
  {
    coty_name: 'Albany',
  },
  {
    coty_name: 'Dunklin',
  },
  {
    coty_name: 'Laclede',
  },
  {
    coty_name: 'Granite',
  },
  {
    coty_name: 'Cedar',
  },
  {
    coty_name: 'Sarpy',
  },
  {
    coty_name: 'Churchill',
  },
  {
    coty_name: 'Strafford',
  },
  {
    coty_name: 'Atlantic',
  },
  {
    coty_name: 'Bernalillo',
  },
  {
    coty_name: 'Steuben',
  },
  {
    coty_name: 'Craven',
  },
  {
    coty_name: 'Pitt',
  },
  {
    coty_name: 'Greenlee',
  },
  {
    coty_name: 'Maricopa',
  },
  {
    coty_name: 'Arkansas',
  },
  {
    coty_name: 'Nevada',
  },
  {
    coty_name: 'Monterey',
  },
  {
    coty_name: 'Sacramento',
  },
  {
    coty_name: 'Trinity',
  },
  {
    coty_name: 'Yolo',
  },
  {
    coty_name: 'Atkinson',
  },
  {
    coty_name: 'Forsyth',
  },
  {
    coty_name: 'Treutlen',
  },
  {
    coty_name: 'Troup',
  },
  {
    coty_name: 'Bingham',
  },
  {
    coty_name: 'McDonough',
  },
  {
    coty_name: 'Vermilion',
  },
  {
    coty_name: 'Porter',
  },
  {
    coty_name: 'Buchanan',
  },
  {
    coty_name: 'Mahaska',
  },
  {
    coty_name: 'Miami',
  },
  {
    coty_name: 'Wyandotte',
  },
  {
    coty_name: 'Barren',
  },
  {
    coty_name: 'Aroostook',
  },
  {
    coty_name: 'Dorchester',
  },
  {
    coty_name: 'Hampden',
  },
  {
    coty_name: 'Gratiot',
  },
  {
    coty_name: 'Lenawee',
  },
  {
    coty_name: 'Mecosta',
  },
  {
    coty_name: 'Big Stone',
  },
  {
    coty_name: 'Watonwan',
  },
  {
    coty_name: 'Kemper',
  },
  {
    coty_name: 'Yazoo',
  },
  {
    coty_name: 'Chouteau',
  },
  {
    coty_name: 'Hayes',
  },
  {
    coty_name: 'Socorro',
  },
  {
    coty_name: 'Cattaraugus',
  },
  {
    coty_name: 'Chenango',
  },
  {
    coty_name: 'Schenectady',
  },
  {
    coty_name: 'Tioga',
  },
  {
    coty_name: 'Westchester',
  },
  {
    coty_name: 'Dare',
  },
  {
    coty_name: 'Nash',
  },
  {
    coty_name: 'Hettinger',
  },
  {
    coty_name: 'McKenzie',
  },
  {
    coty_name: 'Cotton',
  },
  {
    coty_name: 'Okfuskee',
  },
  {
    coty_name: 'Washita',
  },
  {
    coty_name: 'Wallowa',
  },
  {
    coty_name: 'Lycoming',
  },
  {
    coty_name: 'Beaufort',
  },
  {
    coty_name: 'Bennett',
  },
  {
    coty_name: 'Meigs',
  },
  {
    coty_name: 'Tipton',
  },
  {
    coty_name: 'Blanco',
  },
  {
    coty_name: 'Callahan',
  },
  {
    coty_name: 'Childress',
  },
  {
    coty_name: 'Stonewall',
  },
  {
    coty_name: 'Webb',
  },
  {
    coty_name: 'Young',
  },
  {
    coty_name: 'Rich',
  },
  {
    coty_name: 'Racine',
  },
  {
    coty_name: 'Geauga',
  },
  {
    coty_name: 'Cimarron',
  },
  {
    coty_name: 'Woods',
  },
  {
    coty_name: 'Philadelphia',
  },
  {
    coty_name: 'Lexington',
  },
  {
    coty_name: 'Hawkins',
  },
  {
    coty_name: 'Sumner',
  },
  {
    coty_name: 'Bee',
  },
  {
    coty_name: 'Concho',
  },
  {
    coty_name: 'Live Oak',
  },
  {
    coty_name: 'McLennan',
  },
  {
    coty_name: 'Pecos',
  },
  {
    coty_name: 'Wilbarger',
  },
  {
    coty_name: 'Accomack',
  },
  {
    coty_name: 'Lunenburg',
  },
  {
    coty_name: 'Russell',
  },
  {
    coty_name: 'Southampton',
  },
  {
    coty_name: 'Mingo',
  },
  {
    coty_name: 'Pocahontas',
  },
  {
    coty_name: 'Ritchie',
  },
  {
    coty_name: 'Kenosha',
  },
  {
    coty_name: 'Washburn',
  },
  {
    coty_name: 'Niobrara',
  },
  {
    coty_name: 'Chariton',
  },
  {
    coty_name: 'Dent',
  },
  {
    coty_name: 'Hickory',
  },
  {
    coty_name: 'Wibaux',
  },
  {
    coty_name: 'Box Butte',
  },
  {
    coty_name: 'Frontier',
  },
  {
    coty_name: 'Hooker',
  },
  {
    coty_name: 'Otsego',
  },
  {
    coty_name: 'Seneca',
  },
  {
    coty_name: 'Harnett',
  },
  {
    coty_name: 'Merrick',
  },
  {
    coty_name: 'Thayer',
  },
  {
    coty_name: 'White Pine',
  },
  {
    coty_name: 'Etowah',
  },
  {
    coty_name: 'Talladega',
  },
  {
    coty_name: 'Craighead',
  },
  {
    coty_name: 'Drew',
  },
  {
    coty_name: 'Faulkner',
  },
  {
    coty_name: 'Garland',
  },
  {
    coty_name: 'Searcy',
  },
  {
    coty_name: 'Sutter',
  },
  {
    coty_name: 'Gunnison',
  },
  {
    coty_name: 'Montrose',
  },
  {
    coty_name: 'Prowers',
  },
  {
    coty_name: 'Fannin',
  },
  {
    coty_name: 'Glynn',
  },
  {
    coty_name: 'Benewah',
  },
  {
    coty_name: 'Latah',
  },
  {
    coty_name: 'Shawnee',
  },
  {
    coty_name: 'Kenton',
  },
  {
    coty_name: "St. Mary's",
  },
  {
    coty_name: 'Alpena',
  },
  {
    coty_name: 'Cheboygan',
  },
  {
    coty_name: 'Houghton',
  },
  {
    coty_name: 'Ogemaw',
  },
  {
    coty_name: 'Meeker',
  },
  {
    coty_name: 'Chickasaw',
  },
  {
    coty_name: 'Lowndes',
  },
  {
    coty_name: 'Yalobusha',
  },
  {
    coty_name: 'Bates',
  },
  {
    coty_name: 'Conway',
  },
  {
    coty_name: 'Kern',
  },
  {
    coty_name: 'San Francisco',
  },
  {
    coty_name: 'Tulare',
  },
  {
    coty_name: 'Denver',
  },
  {
    coty_name: 'Rio Blanco',
  },
  {
    coty_name: 'Weld',
  },
  {
    coty_name: 'Levy',
  },
  {
    coty_name: 'Boundary',
  },
  {
    coty_name: 'Power',
  },
  {
    coty_name: 'De Witt',
  },
  {
    coty_name: 'DuPage',
  },
  {
    coty_name: 'Iroquois',
  },
  {
    coty_name: 'Blackford',
  },
  {
    coty_name: 'Elkhart',
  },
  {
    coty_name: 'Cerro Gordo',
  },
  {
    coty_name: "O'Brien",
  },
  {
    coty_name: 'Atchison',
  },
  {
    coty_name: 'Barber',
  },
  {
    coty_name: 'Republic',
  },
  {
    coty_name: 'Casey',
  },
  {
    coty_name: 'Hopkins',
  },
  {
    coty_name: 'Muhlenberg',
  },
  {
    coty_name: 'Plymouth',
  },
  {
    coty_name: 'Isanti',
  },
  {
    coty_name: 'Pearl River',
  },
  {
    coty_name: 'Prentiss',
  },
  {
    coty_name: 'Reynolds',
  },
  {
    coty_name: 'Dundy',
  },
  {
    coty_name: 'Furnas',
  },
  {
    coty_name: 'Passaic',
  },
  {
    coty_name: 'New York',
  },
  {
    coty_name: 'Yancey',
  },
  {
    coty_name: 'Bowman',
  },
  {
    coty_name: 'Ashtabula',
  },
  {
    coty_name: 'Trumbull',
  },
  {
    coty_name: 'Muskogee',
  },
  {
    coty_name: 'Blair',
  },
  {
    coty_name: 'Bucks',
  },
  {
    coty_name: 'Darlington',
  },
  {
    coty_name: 'Bon Homme',
  },
  {
    coty_name: 'Mellette',
  },
  {
    coty_name: 'McMinn',
  },
  {
    coty_name: 'Unicoi',
  },
  {
    coty_name: 'Dickens',
  },
  {
    coty_name: 'Gregg',
  },
  {
    coty_name: 'Hudspeth',
  },
  {
    coty_name: 'San Patricio',
  },
  {
    coty_name: 'Tarrant',
  },
  {
    coty_name: 'Bennington',
  },
  {
    coty_name: 'Appomattox',
  },
  {
    coty_name: 'Rockbridge',
  },
  {
    coty_name: 'Tyler',
  },
  {
    coty_name: 'Barron',
  },
  {
    coty_name: 'La Crosse',
  },
  {
    coty_name: 'Shawano',
  },
  {
    coty_name: 'Cullman',
  },
  {
    coty_name: 'Coconino',
  },
  {
    coty_name: 'Fresno',
  },
  {
    coty_name: 'Mendocino',
  },
  {
    coty_name: 'Stanislaus',
  },
  {
    coty_name: 'Pasco',
  },
  {
    coty_name: 'Candler',
  },
  {
    coty_name: 'Lemhi',
  },
  {
    coty_name: 'Kankakee',
  },
  {
    coty_name: 'Buena Vista',
  },
  {
    coty_name: 'Trego',
  },
  {
    coty_name: 'Garrard',
  },
  {
    coty_name: 'Redwood',
  },
  {
    coty_name: 'Neshoba',
  },
  {
    coty_name: 'Treasure',
  },
  {
    coty_name: 'Banner',
  },
  {
    coty_name: 'Seward',
  },
  {
    coty_name: 'Thurston',
  },
  {
    coty_name: 'Washoe',
  },
  {
    coty_name: 'Burlington',
  },
  {
    coty_name: 'Yates',
  },
  {
    coty_name: 'Caswell',
  },
  {
    coty_name: 'Stanly',
  },
  {
    coty_name: 'Watauga',
  },
  {
    coty_name: 'Kidder',
  },
  {
    coty_name: 'Clermont',
  },
  {
    coty_name: 'Creek',
  },
  {
    coty_name: 'Nowata',
  },
  {
    coty_name: 'Okmulgee',
  },
  {
    coty_name: 'Aurora',
  },
  {
    coty_name: 'Charles Mix',
  },
  {
    coty_name: 'McCook',
  },
  {
    coty_name: 'Rhea',
  },
  {
    coty_name: 'Runnels',
  },
  {
    coty_name: 'Scurry',
  },
  {
    coty_name: 'Pittsylvania',
  },
  {
    coty_name: 'Wythe',
  },
  {
    coty_name: 'Spokane',
  },
  {
    coty_name: 'Pleasants',
  },
  {
    coty_name: 'St. Charles',
  },
  {
    coty_name: 'Flathead',
  },
  {
    coty_name: 'Judith Basin',
  },
  {
    coty_name: 'Musselshell',
  },
  {
    coty_name: 'Garden',
  },
  {
    coty_name: 'Hitchcock',
  },
  {
    coty_name: 'Saunders',
  },
  {
    coty_name: 'Broome',
  },
  {
    coty_name: 'Cortland',
  },
  {
    coty_name: 'Saratoga',
  },
  {
    coty_name: 'Catawba',
  },
  {
    coty_name: 'Barnes',
  },
  {
    coty_name: 'Oliver',
  },
  {
    coty_name: 'Slope',
  },
  {
    coty_name: 'Malheur',
  },
  {
    coty_name: 'Montour',
  },
  {
    coty_name: 'Stanley',
  },
  {
    coty_name: 'Cheatham',
  },
  {
    coty_name: 'Stewart',
  },
  {
    coty_name: 'Williamson',
  },
  {
    coty_name: 'Atascosa',
  },
  {
    coty_name: 'Bastrop',
  },
  {
    coty_name: 'Coryell',
  },
  {
    coty_name: 'Fisher',
  },
  {
    coty_name: 'Grimes',
  },
  {
    coty_name: 'Hood',
  },
  {
    coty_name: 'Jim Wells',
  },
  {
    coty_name: 'San Jacinto',
  },
  {
    coty_name: 'Tom Green',
  },
  {
    coty_name: 'Van Zandt',
  },
  {
    coty_name: 'Prince Edward',
  },
  {
    coty_name: 'Kittitas',
  },
  {
    coty_name: 'Wetzel',
  },
  {
    coty_name: 'Fond du Lac',
  },
  {
    coty_name: 'Cleburne',
  },
  {
    coty_name: 'Calaveras',
  },
  {
    coty_name: 'Glenn',
  },
  {
    coty_name: 'Madera',
  },
  {
    coty_name: 'Merced',
  },
  {
    coty_name: 'Plumas',
  },
  {
    coty_name: 'Dolores',
  },
  {
    coty_name: 'Montezuma',
  },
  {
    coty_name: 'Wakulla',
  },
  {
    coty_name: 'Bleckley',
  },
  {
    coty_name: 'Chattahoochee',
  },
  {
    coty_name: 'Elbert',
  },
  {
    coty_name: 'Habersham',
  },
  {
    coty_name: 'Cassia',
  },
  {
    coty_name: 'Bartholomew',
  },
  {
    coty_name: 'Audubon',
  },
  {
    coty_name: 'Dubuque',
  },
  {
    coty_name: 'Ballard',
  },
  {
    coty_name: 'Calvert',
  },
  {
    coty_name: 'Cecil',
  },
  {
    coty_name: 'Presque Isle',
  },
  {
    coty_name: 'Beltrami',
  },
  {
    coty_name: 'Faribault',
  },
  {
    coty_name: 'Freeborn',
  },
  {
    coty_name: 'Hennepin',
  },
  {
    coty_name: 'Nobles',
  },
  {
    coty_name: 'Wabasha',
  },
  {
    coty_name: 'Mariposa',
  },
  {
    coty_name: 'Shasta',
  },
  {
    coty_name: 'Sonoma',
  },
  {
    coty_name: 'Hinsdale',
  },
  {
    coty_name: 'Las Animas',
  },
  {
    coty_name: 'Banks',
  },
  {
    coty_name: 'Catoosa',
  },
  {
    coty_name: 'Wabaunsee',
  },
  {
    coty_name: 'Breathitt',
  },
  {
    coty_name: 'Estill',
  },
  {
    coty_name: 'Wolfe',
  },
  {
    coty_name: 'Hampshire',
  },
  {
    coty_name: 'Benzie',
  },
  {
    coty_name: 'Marquette',
  },
  {
    coty_name: 'Attala',
  },
  {
    coty_name: 'Foster',
  },
  {
    coty_name: 'Auglaize',
  },
  {
    coty_name: 'Coshocton',
  },
  {
    coty_name: 'Garvin',
  },
  {
    coty_name: 'Oklahoma',
  },
  {
    coty_name: 'Indiana',
  },
  {
    coty_name: 'Lebanon',
  },
  {
    coty_name: 'Aiken',
  },
  {
    coty_name: 'Bledsoe',
  },
  {
    coty_name: 'Haywood',
  },
  {
    coty_name: 'Angelina',
  },
  {
    coty_name: 'Bexar',
  },
  {
    coty_name: 'Donley',
  },
  {
    coty_name: 'Galveston',
  },
  {
    coty_name: 'McMullen',
  },
  {
    coty_name: 'Milam',
  },
  {
    coty_name: 'Navarro',
  },
  {
    coty_name: 'Parker',
  },
  {
    coty_name: 'Val Verde',
  },
  {
    coty_name: 'Wasatch',
  },
  {
    coty_name: 'Caledonia',
  },
  {
    coty_name: 'Alleghany',
  },
  {
    coty_name: 'Arlington',
  },
  {
    coty_name: 'Okanogan',
  },
  {
    coty_name: 'Door',
  },
  {
    coty_name: 'Sweetwater',
  },
  {
    coty_name: 'Deer Lodge',
  },
  {
    coty_name: 'Meagher',
  },
  {
    coty_name: 'Burt',
  },
  {
    coty_name: 'Cherry',
  },
  {
    coty_name: 'Los Alamos',
  },
  {
    coty_name: 'San Miguel',
  },
  {
    coty_name: 'Ontario',
  },
  {
    coty_name: 'Rensselaer',
  },
  {
    coty_name: 'Ashe',
  },
  {
    coty_name: 'Hertford',
  },
  {
    coty_name: 'Dallas',
  },
  {
    coty_name: 'Independence',
  },
  {
    coty_name: 'Izard',
  },
  {
    coty_name: 'Lassen',
  },
  {
    coty_name: 'Placer',
  },
  {
    coty_name: 'Arapahoe',
  },
  {
    coty_name: 'Conejos',
  },
  {
    coty_name: 'Citrus',
  },
  {
    coty_name: 'Jenkins',
  },
  {
    coty_name: 'Peach',
  },
  {
    coty_name: 'LaSalle',
  },
  {
    coty_name: 'Will',
  },
  {
    coty_name: 'Dubois',
  },
  {
    coty_name: 'Keokuk',
  },
  {
    coty_name: 'Finney',
  },
  {
    coty_name: 'Laurel',
  },
  {
    coty_name: 'Anoka',
  },
  {
    coty_name: 'Winona',
  },
  {
    coty_name: 'Hinds',
  },
  {
    coty_name: 'Eddy',
  },
  {
    coty_name: 'Multnomah',
  },
  {
    coty_name: 'Newport',
  },
  {
    coty_name: 'Williamsburg',
  },
  {
    coty_name: 'Beadle',
  },
  {
    coty_name: 'Dyer',
  },
  {
    coty_name: 'Maury',
  },
  {
    coty_name: 'Brooks',
  },
  {
    coty_name: 'Castro',
  },
  {
    coty_name: 'Colorado',
  },
  {
    coty_name: 'Eastland',
  },
  {
    coty_name: 'Gonzales',
  },
  {
    coty_name: 'La Salle',
  },
  {
    coty_name: 'Bland',
  },
  {
    coty_name: 'Prince William',
  },
  {
    coty_name: 'Ferry',
  },
  {
    coty_name: 'Pend Oreille',
  },
  {
    coty_name: 'Taney',
  },
  {
    coty_name: 'Lewis and Clark',
  },
  {
    coty_name: 'Buffalo',
  },
  {
    coty_name: 'Doña Ana',
  },
  {
    coty_name: 'Anson',
  },
  {
    coty_name: 'Bladen',
  },
  {
    coty_name: 'Sampson',
  },
  {
    coty_name: 'Keith',
  },
  {
    coty_name: 'Loup',
  },
  {
    coty_name: 'La Paz',
  },
  {
    coty_name: 'Pinal',
  },
  {
    coty_name: 'Siskiyou',
  },
  {
    coty_name: 'Okeechobee',
  },
  {
    coty_name: 'Volusia',
  },
  {
    coty_name: 'Ben Hill',
  },
  {
    coty_name: 'Toombs',
  },
  {
    coty_name: 'Gooding',
  },
  {
    coty_name: 'Rock Island',
  },
  {
    coty_name: 'Story',
  },
  {
    coty_name: 'Graham',
  },
  {
    coty_name: 'Harvey',
  },
  {
    coty_name: 'Bracken',
  },
  {
    coty_name: 'Menifee',
  },
  {
    coty_name: 'Roscommon',
  },
  {
    coty_name: 'Saginaw',
  },
  {
    coty_name: 'Carver',
  },
  {
    coty_name: 'Itasca',
  },
  {
    coty_name: 'Grenada',
  },
  {
    coty_name: 'Mountrail',
  },
  {
    coty_name: 'Licking',
  },
  {
    coty_name: 'Muskingum',
  },
  {
    coty_name: 'Alfalfa',
  },
  {
    coty_name: 'Providence',
  },
  {
    coty_name: 'Abbeville',
  },
  {
    coty_name: 'Miner',
  },
  {
    coty_name: 'Archer',
  },
  {
    coty_name: 'Baylor',
  },
  {
    coty_name: 'Gillespie',
  },
  {
    coty_name: 'Jack',
  },
  {
    coty_name: 'Matagorda',
  },
  {
    coty_name: 'Hanover',
  },
  {
    coty_name: 'Rappahannock',
  },
  {
    coty_name: 'Walla Walla',
  },
  {
    coty_name: 'Berkeley',
  },
  {
    coty_name: 'Braxton',
  },
  {
    coty_name: 'Carteret',
  },
  {
    coty_name: 'Guilford',
  },
  {
    coty_name: 'New Hanover',
  },
  {
    coty_name: 'Swain',
  },
  {
    coty_name: 'St. Francois',
  },
  {
    coty_name: 'Fallon',
  },
  {
    coty_name: 'Pondera',
  },
  {
    coty_name: 'Morrill',
  },
  {
    coty_name: 'Hudson',
  },
  {
    coty_name: 'Hidalgo',
  },
  {
    coty_name: 'Onondaga',
  },
  {
    coty_name: 'Imperial',
  },
  {
    coty_name: 'San Diego',
  },
  {
    coty_name: 'San Luis Obispo',
  },
  {
    coty_name: 'Tuolumne',
  },
  {
    coty_name: 'Teller',
  },
  {
    coty_name: 'Flagler',
  },
  {
    coty_name: 'Pinellas',
  },
  {
    coty_name: 'Haralson',
  },
  {
    coty_name: 'Schley',
  },
  {
    coty_name: 'Ware',
  },
  {
    coty_name: 'Whitfield',
  },
  {
    coty_name: 'Kalawao',
  },
  {
    coty_name: 'Kauai',
  },
  {
    coty_name: 'Caribou',
  },
  {
    coty_name: 'Kootenai',
  },
  {
    coty_name: 'Owyhee',
  },
  {
    coty_name: 'Kane',
  },
  {
    coty_name: 'Peoria',
  },
  {
    coty_name: 'LaGrange',
  },
  {
    coty_name: 'Neosho',
  },
  {
    coty_name: 'Rawlins',
  },
  {
    coty_name: 'Calloway',
  },
  {
    coty_name: 'Penobscot',
  },
  {
    coty_name: 'Allegan',
  },
  {
    coty_name: 'Wadena',
  },
  {
    coty_name: 'Gentry',
  },
  {
    coty_name: 'Oregon',
  },
  {
    coty_name: 'Ozark',
  },
  {
    coty_name: 'Ste. Genevieve',
  },
  {
    coty_name: 'Stillwater',
  },
  {
    coty_name: 'Keya Paha',
  },
  {
    coty_name: 'Hunterdon',
  },
  {
    coty_name: 'Salem',
  },
  {
    coty_name: 'De Baca',
  },
  {
    coty_name: 'Chowan',
  },
  {
    coty_name: 'Ward',
  },
  {
    coty_name: 'Lorain',
  },
  {
    coty_name: 'Woodward',
  },
  {
    coty_name: 'Clackamas',
  },
  {
    coty_name: 'Edgefield',
  },
  {
    coty_name: 'Horry',
  },
  {
    coty_name: 'Haakon',
  },
  {
    coty_name: 'Minnehaha',
  },
  {
    coty_name: 'Tripp',
  },
  {
    coty_name: 'Overton',
  },
  {
    coty_name: 'Aransas',
  },
  {
    coty_name: 'Sabine',
  },
  {
    coty_name: 'Throckmorton',
  },
  {
    coty_name: 'Duchesne',
  },
  {
    coty_name: 'King William',
  },
  {
    coty_name: 'Hardy',
  },
  {
    coty_name: 'Waukesha',
  },
  {
    coty_name: 'Converse',
  },
  {
    coty_name: 'Sargent',
  },
  {
    coty_name: 'Mahoning',
  },
  {
    coty_name: 'Klamath',
  },
  {
    coty_name: 'Allegheny',
  },
  {
    coty_name: 'Spink',
  },
  {
    coty_name: 'Cannon',
  },
  {
    coty_name: 'Sequatchie',
  },
  {
    coty_name: 'Weakley',
  },
  {
    coty_name: 'Bowie',
  },
  {
    coty_name: 'Crane',
  },
  {
    coty_name: 'Motley',
  },
  {
    coty_name: 'Loudoun',
  },
  {
    coty_name: 'Patrick',
  },
  {
    coty_name: 'Smyth',
  },
  {
    coty_name: 'Tucker',
  },
  {
    coty_name: 'Hot Springs',
  },
  {
    coty_name: 'Washakie',
  },
  {
    coty_name: 'Weston',
  },
  {
    coty_name: 'Daniels',
  },
  {
    coty_name: 'Bergen',
  },
  {
    coty_name: 'Luna',
  },
  {
    coty_name: 'Bronx',
  },
  {
    coty_name: 'Tyrrell',
  },
  {
    coty_name: 'Gasconade',
  },
  {
    coty_name: 'Moniteau',
  },
  {
    coty_name: 'Phelps',
  },
  {
    coty_name: 'Geneva',
  },
  {
    coty_name: 'Marengo',
  },
  {
    coty_name: 'Mohave',
  },
  {
    coty_name: 'Palm Beach',
  },
  {
    coty_name: 'Menard',
  },
  {
    coty_name: 'Ogle',
  },
  {
    coty_name: 'Jay',
  },
  {
    coty_name: 'Des Moines',
  },
  {
    coty_name: 'Doniphan',
  },
  {
    coty_name: 'Geary',
  },
  {
    coty_name: 'Rooks',
  },
  {
    coty_name: 'Bullitt',
  },
  {
    coty_name: 'Leslie',
  },
  {
    coty_name: 'Oxford',
  },
  {
    coty_name: 'Baltimore',
  },
  {
    coty_name: 'Hillsdale',
  },
  {
    coty_name: 'Kalkaska',
  },
  {
    coty_name: 'Swift',
  },
  {
    coty_name: 'Leake',
  },
  {
    coty_name: 'Noxubee',
  },
  {
    coty_name: 'Sunflower',
  },
  {
    coty_name: 'Callaway',
  },
  {
    coty_name: 'Alameda',
  },
  {
    coty_name: 'San Mateo',
  },
  {
    coty_name: 'Boulder',
  },
  {
    coty_name: 'Dixie',
  },
  {
    coty_name: 'Manatee',
  },
  {
    coty_name: 'St. Johns',
  },
  {
    coty_name: 'Bryan',
  },
  {
    coty_name: 'Evans',
  },
  {
    coty_name: 'Heard',
  },
  {
    coty_name: 'Irwin',
  },
  {
    coty_name: 'Towns',
  },
  {
    coty_name: 'Massac',
  },
  {
    coty_name: 'Tama',
  },
  {
    coty_name: 'Reno',
  },
  {
    coty_name: 'Wallace',
  },
  {
    coty_name: 'Elliott',
  },
  {
    coty_name: 'Magoffin',
  },
  {
    coty_name: 'Metcalfe',
  },
  {
    coty_name: 'Wicomico',
  },
  {
    coty_name: 'Alger',
  },
  {
    coty_name: 'Antrim',
  },
  {
    coty_name: 'Ionia',
  },
  {
    coty_name: 'Kittson',
  },
  {
    coty_name: 'Bolivar',
  },
  {
    coty_name: 'Audrain',
  },
  {
    coty_name: 'Roger Mills',
  },
  {
    coty_name: 'Sequoyah',
  },
  {
    coty_name: 'Lackawanna',
  },
  {
    coty_name: 'Northampton',
  },
  {
    coty_name: 'Orangeburg',
  },
  {
    coty_name: 'McNairy',
  },
  {
    coty_name: 'Coke',
  },
  {
    coty_name: 'Erath',
  },
  {
    coty_name: 'Lynn',
  },
  {
    coty_name: 'San Saba',
  },
  {
    coty_name: 'Chittenden',
  },
  {
    coty_name: 'Powhatan',
  },
  {
    coty_name: 'Greenbrier',
  },
  {
    coty_name: 'Preston',
  },
  {
    coty_name: 'Bayfield',
  },
  {
    coty_name: 'Missoula',
  },
  {
    coty_name: 'Cheshire',
  },
  {
    coty_name: 'Lea',
  },
  {
    coty_name: 'Santa Fe',
  },
  {
    coty_name: 'Niagara',
  },
  {
    coty_name: 'Solano',
  },
  {
    coty_name: 'Crowley',
  },
  {
    coty_name: 'Hernando',
  },
  {
    coty_name: 'Cowley',
  },
  {
    coty_name: 'Kearny',
  },
  {
    coty_name: 'Leavenworth',
  },
  {
    coty_name: 'McCreary',
  },
  {
    coty_name: 'Rockcastle',
  },
  {
    coty_name: 'Anne Arundel',
  },
  {
    coty_name: 'Dukes',
  },
  {
    coty_name: 'Manistee',
  },
  {
    coty_name: 'Oakland',
  },
  {
    coty_name: 'Tuscola',
  },
  {
    coty_name: 'Carlton',
  },
  {
    coty_name: 'Athens',
  },
  {
    coty_name: 'Darke',
  },
  {
    coty_name: 'Ross',
  },
  {
    coty_name: 'Vinton',
  },
  {
    coty_name: 'Caddo',
  },
  {
    coty_name: 'Hampton',
  },
  {
    coty_name: 'Ziebach',
  },
  {
    coty_name: 'Dimmit',
  },
  {
    coty_name: 'Goliad',
  },
  {
    coty_name: 'Hays',
  },
  {
    coty_name: 'Hunt',
  },
  {
    coty_name: 'Maverick',
  },
  {
    coty_name: 'Refugio',
  },
  {
    coty_name: 'Wharton',
  },
  {
    coty_name: 'Salt Lake',
  },
  {
    coty_name: 'Windsor',
  },
  {
    coty_name: 'James City',
  },
  {
    coty_name: 'Whitman',
  },
  {
    coty_name: 'Brooke',
  },
  {
    coty_name: 'Wirt',
  },
  {
    coty_name: 'Uinta',
  },
  {
    coty_name: 'Howell',
  },
  {
    coty_name: 'Ralls',
  },
  {
    coty_name: 'Powder River',
  },
  {
    coty_name: 'Dawes',
  },
  {
    coty_name: 'Dutchess',
  },
  {
    coty_name: 'Lenoir',
  },
  {
    coty_name: 'Onslow',
  },
  {
    coty_name: 'Sweet Grass',
  },
  {
    coty_name: 'Duplin',
  },
  {
    coty_name: 'Perquimans',
  },
  {
    coty_name: 'Person',
  },
  {
    coty_name: 'Vance',
  },
  {
    coty_name: 'Bottineau',
  },
  {
    coty_name: 'Pima',
  },
  {
    coty_name: 'Los Angeles',
  },
  {
    coty_name: 'San Joaquin',
  },
  {
    coty_name: 'Ventura',
  },
  {
    coty_name: 'Gilpin',
  },
  {
    coty_name: 'Bulloch',
  },
  {
    coty_name: 'Early',
  },
  {
    coty_name: 'Maui',
  },
  {
    coty_name: 'Bear Lake',
  },
  {
    coty_name: 'Whiteside',
  },
  {
    coty_name: 'Hendricks',
  },
  {
    coty_name: 'Breckinridge',
  },
  {
    coty_name: 'Knott',
  },
  {
    coty_name: "Prince George's",
  },
  {
    coty_name: 'Norfolk',
  },
  {
    coty_name: 'Clare',
  },
  {
    coty_name: 'Eaton',
  },
  {
    coty_name: 'Muskegon',
  },
  {
    coty_name: 'Traverse',
  },
  {
    coty_name: 'Jefferson Davis',
  },
  {
    coty_name: 'Van Wert',
  },
  {
    coty_name: 'Tulsa',
  },
  {
    coty_name: 'Barnwell',
  },
  {
    coty_name: 'Colleton',
  },
  {
    coty_name: 'Day',
  },
  {
    coty_name: 'Brewster',
  },
  {
    coty_name: 'Dallam',
  },
  {
    coty_name: 'Hansford',
  },
  {
    coty_name: 'Kleberg',
  },
  {
    coty_name: 'Llano',
  },
  {
    coty_name: 'Fairfax',
  },
  {
    coty_name: 'King George',
  },
  {
    coty_name: 'Doddridge',
  },
  {
    coty_name: 'Summers',
  },
  {
    coty_name: 'Eau Claire',
  },
  {
    coty_name: 'Milwaukee',
  },
  {
    coty_name: 'Sheboygan',
  },
  {
    coty_name: 'Vilas',
  },
]

export const onSubmitTaxRelatedValidation = (checkObj, data, error) => {
  let errObj = error
  let IsValid = true
  let errorShow = true
  checkObj.map(item => {
    if (!data?.[item]) {
      errObj = { ...errObj, [item]: 'This field is required' }
      IsValid = false
    } else if (item == 'postal_code') {
      let postalCoderegx = /(^[0-9]{5}(?:-[0-9]{4})?$)/
      if (!postalCoderegx.test(data?.[item])) {
        errObj = { ...errObj, [item]: 'Invalid Postalcode' }
        IsValid = false
      }
    }
    if (item == 'substantial_us_owners_passive_nffe' && !data?.[item]) {
      errObj = { ...errObj, [item]: 'Minimum one U.S. owner is required.' }
      IsValid = false
    }

    if (item == 'substantial_us_owners_passive_nffe' && typeof error[item] === 'object') {
      // errObj = { ...errObj, [item]: 'Minimum one U.S. owner is required.' }
      // IsValid = false
    }

    if (item == 'nonreporting_iga_ffi_country' && !data?.[item]) {
      errObj = { ...errObj, [item]: 'Country is required.' }
      IsValid = false
    }
    if (item == 'applicable_iga_treated_as' && !data?.[item]) {
      errObj = { ...errObj, [item]: 'IGA treated as field is required.' }
      IsValid = false
    }
    if (
      (item == 'publicly_traded_nffe_option2_name_of_entity' ||
        item == 'publicly_traded_nffe_option2_name_of_securities_market') &&
      !data?.[item]
    ) {
      errObj = { ...errObj, [item]: 'Both fields are required.' }
      IsValid = false
    }
    if (item == 'is_trustee_documented_trust' && !data?.[item]) {
      errObj = { ...errObj, [item]: 'Selecting from dropdown is required.' }
      IsValid = false
    }
    if (
      (item == 'excepted_nonfinancial_startup_date' ||
        item == 'excepted_nonfinancial_in_liquidation_date' ||
        item == 'type_1_date_501') &&
      !data?.[item]
    ) {
      errObj = { ...errObj, [item]: 'This date field is required.' }
      IsValid = false
    }
    if (
      (item == 'sponsoring_entity_certification_type' ||
        item == 'restricted_distributor_further_certification_type' ||
        item == 'applicable_iga_type' ||
        item == 'trustee_type' ||
        item == 'international_organization_certification_type' ||
        item == 'exempt_retirement_certification_type' ||
        item == 'certification_type_501' ||
        item == 'passive_nffe_certification_type') &&
      !data?.[item]
    ) {
      errObj = { ...errObj, [item]: 'One of the above types is required.' }
      IsValid = false
    }
    const filterCheck = Object.values(errObj)?.filter(item => !!item)
    if (filterCheck?.length !== 0) {
      errorShow = true
    } else {
      errorShow = false
    }
  })

  if (Object.values(errObj)) return { errors_obj: errObj, status: IsValid, showError: errorShow }
}

export const onChangeTaxRelatedValidation = (field, value, error) => {
  let erroObj = { ...error }
  if (value) {
    erroObj[field] = ''
  }
  if (value !== 0 && !value) {
    erroObj[field] = 'This field is required'
  }
  if (
    (field == 'publicly_traded_nffe_option2_name_of_entity' ||
      field == 'publicly_traded_nffe_option2_name_of_securities_market') &&
    !value
  ) {
    erroObj[field] = 'Both fields are required.'
  }
  if (field == 'name' && value) {
    let regx = /^[a-zA-Z]+$/
    if (!regx.test(value?.trimStart())) {
      erroObj[field] = 'Name must be character only'
    }
  }
  if ((field == 'postal_code' || field == 'mailing_postal_code') && value) {
    let postalCoderegx = /(^[0-9]{5}(?:-[0-9]{4})?$)/
    if (!postalCoderegx.test(value)) {
      erroObj[field] = 'Invalid Postal code'
    }
  }
  if (field == 'birth_date' && value) {
    const enterDate = new Date(formatDate(value)) // Parse the entered date
    const date18yrsAgo = new Date()
    const date125yrsAgo = new Date()
    date18yrsAgo.setFullYear(date18yrsAgo.getFullYear() - 18)
    date125yrsAgo.setFullYear(date125yrsAgo.getFullYear() - 125)
    const valid = enterDate <= date18yrsAgo && enterDate >= date125yrsAgo

    if (!valid) {
      erroObj[field] = 'Invalid Date of Birth'
    } else {
      erroObj[field] = '' // Clear the error if the date is valid
    }
  }

  return erroObj
}

export const countryListScoutEntity = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo (Congo-Brazzaville)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic (Czechia)',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini (fmr. Swaziland)',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (formerly Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

export const fundingSourcesOfIndividual = [
  { name: 'Income from investments' },
  { name: 'Sale of company' },
  { name: 'Sale of investments/liquidation of investment portfolio' },
  { name: 'Employment income' },
  { name: 'Business ownership' },
  { name: 'Inheritance or family wealth' },
  { name: 'Gift' },
  { name: 'Life insurance' },
  { name: 'Other' },
]

export const fundingSourcesOfTrust = [
  { name: 'Income from investments' },
  { name: 'Company profits' },
  { name: 'Sale of company' },
  { name: 'Sale of investments/liquidation of investment portfolio' },
  { name: 'Employment income' },
  { name: 'Inheritance or family wealth' },
  { name: 'Gift' },
  { name: 'Life insurance' },
  { name: 'Capital sourced from investors' },
  { name: 'Other' },
]

export const StatesListOfUnitedStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

export const ProvinceListOfCanada = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Nova Scotia',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
]

export const FundTypeListing = [
  'LLC',
  'Corporation',
  'S-Corporation',
  'Partnership',
  'Limited Partnership',
  'Estate',
  'Exempt Organization',
]

export function formatSize(bytes) {
  if (bytes < 1024) {
    return `${bytes} B`
  } else if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)} KB`
  } else {
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`
  }
}

export function isSafariOnMac() {
  const userAgent = navigator.userAgent
  const isSafari = userAgent.indexOf('Safari') !== -1
  const isMac = userAgent.indexOf('Macintosh') !== -1

  if (isSafari && isMac) {
    return true
  } else {
    return false
  }
}

export function formatDate(dateString) {
  const [month, day, year] = dateString.split('-')
  return `${month}/${day}/${year}`
}

export const convertUTCToLocal = utcDateString => {
  // Get the user's local time zone (e.g., 'America/New_York', 'Asia/Kolkata', etc.)
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // Parse the UTC datetime string to a Date object
  const utcDate = new Date(utcDateString)

  // Convert the UTC date to the user's local time zone using toLocaleString
  const localDateString = utcDate.toLocaleString('en-US', { timeZone: userTimeZone })
  const newDate = localDateString.split(',')[0]
  return newDate?.split('/').join('-')
}

export function isSafariOnIphone() {
  const userAgent = navigator.userAgent
  return /iPhone/i.test(userAgent) && /Safari/i.test(userAgent)
}

export const newCountryListScoutEntity = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Brazil', code: 'BR' },
  { name: 'Brunei', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cabo Verde', code: 'CV' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo (Congo-Brazzaville)', code: 'CG' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic (Czechia)', code: 'CZ' },
  { name: 'Democratic Republic of the Congo', code: 'CD' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Eswatini (fmr. Swaziland)', code: 'SZ' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Greece', code: 'GR' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Laos', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia', code: 'FM' },
  { name: 'Moldova', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar (formerly Burma)', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'North Korea', code: 'KP' },
  { name: 'North Macedonia', code: 'MK' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestine', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russia', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia', code: 'RS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Korea', code: 'KR' },
  { name: 'South Sudan', code: 'SS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syria', code: 'SY' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States of America', code: 'US' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Vatican City', code: 'VA' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
]

export const handleNameFromUrl = url => {
  const fileNameArr = url.split('-')
  const fileName = fileNameArr[fileNameArr.length - 1]
  return fileName
}
